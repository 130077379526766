import { CommentTemplate } from "../models/comment_template";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";

export namespace CommentTemplateAPI {
    export const list = async () => {
        return HTTPApi.get("/comment_templates/list").then((res) => {
            return res.data as CommentTemplate[]
        })
    }


    export const get = async (id: ObjectId) => {
        return HTTPApi.get("/comment_templates/get/" + id.$oid).then((res) => {
            return res.data as CommentTemplate
        })
    }

    export const create = async (payload : Comment) => {
        return HTTPApi.post("comment_templates/create", payload).then((res) => {
            return res.data as CommentTemplate
        })
    }

    export const update = async (payload : Comment) => {
        return HTTPApi.post("comment_templates/update", payload).then((res) => {
            return res.data as CommentTemplate
        })
    }

    export const remove = async (id: ObjectId) => {
        return HTTPApi.get("/comment_templates/delete/" + id.$oid).then((res) => {
            return res.data as CommentTemplate
        })
    }

}