import React, { useState, useEffect } from "react";
import {
    Badge,
    Box,
    HStack,
    Input,
    Skeleton,
    Stack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    useToast,
} from "@chakra-ui/react";
import { EvaluationEntryAPI } from "../../../api/EvaluationEntryAPI";
import { Evaluation } from "../../../models/evaluation";
import { EvaluationEntry } from "../../../models/evaluation_entry";
import { Student } from "../../../models/student";

interface EvaluationEntryTableProps {
    evaluation: Evaluation;
    entries: {
        student: Student;
        evaluation_entry: EvaluationEntry;
    }[];
    refreshEntries: () => void;
}

export const EvaluationEntryTable = ({
                                         evaluation,
                                         entries,
                                         refreshEntries,
                                     }: EvaluationEntryTableProps) => {
    const toast = useToast();

    const handleUpdateEntry = async (entry: EvaluationEntry, markValue: string) => {
        try {
            const newMark = markValue ? Number(markValue) : undefined;
            if (entry.mark != newMark) {
                const updatedEntry = { ...entry, mark: newMark };
                await EvaluationEntryAPI.update_evaluation_entry(updatedEntry);
                toast({
                    title: "Success",
                    description: "Updated entry successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                refreshEntries();
            }
        } catch (error) {
            console.error("Failed to update entry:", error);
            toast({
                title: "Error",
                description: "Failed to update entry.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    if (entries.length == 0) {
        return (
            <Stack>
                {[...Array(5)].map((_, i) => (
                    <Skeleton height="50px" key={i} />
                ))}
            </Stack>
        );
    }

    return (
        <Box overflowX="auto">
            <Table variant="simple" size="sm">
                <Thead>
                    <Tr>
                        <Th>Student Name</Th>
                        <Th>Mark</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {entries.map(({ student, evaluation_entry }) => (
                        <EvaluationEntryRow
                            key={student._id.$oid}
                            student={student}
                            evaluationEntry={evaluation_entry}
                            evaluationTotal={evaluation.total}
                            handleUpdateEntry={handleUpdateEntry}
                        />
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

interface EvaluationEntryRowProps {
    student: Student;
    evaluationEntry: EvaluationEntry;
    evaluationTotal: number;
    handleUpdateEntry: (entry: EvaluationEntry, markValue: string) => void;
}

const EvaluationEntryRow = ({
                                student,
                                evaluationEntry,
                                evaluationTotal,
                                handleUpdateEntry,
                            }: EvaluationEntryRowProps) => {
    const [mark, setMark] = useState<string>(
        evaluationEntry.mark != undefined ? evaluationEntry.mark.toString() : ""
    );
    const [originalMark, setOriginalMark] = useState<string>(
        evaluationEntry.mark != undefined ? evaluationEntry.mark.toString() : ""
    );

    // Update local state when evaluationEntry.mark changes
    useEffect(() => {
        const newMarkString = evaluationEntry.mark != undefined ? evaluationEntry.mark.toString() : "";
        setMark(newMarkString);
        setOriginalMark(newMarkString);
    }, [evaluationEntry.mark]);

    const percentage =
        evaluationEntry.mark != undefined && evaluationTotal != 0
            ? ((evaluationEntry.mark / evaluationTotal) * 100).toFixed(2)
            : null;

    const onBlurHandler = () => {
        if (mark != originalMark) {
            handleUpdateEntry(evaluationEntry, mark);
        }
    };

    return (
        <Tr>
            <Td>
                {student.profile.first_name} {student.profile.last_name}
            </Td>
            <Td>
                <HStack>
                    <Input
                        width="80px"
                        value={mark}
                        placeholder="N/A"
                        onChange={(e) => setMark(e.target.value)}
                        onBlur={onBlurHandler}
                    />
                    <Text>/ {evaluationTotal}</Text>
                    {percentage && (
                        <Badge colorScheme="green" ml={2}>
                            {percentage}%
                        </Badge>
                    )}
                </HStack>
            </Td>
        </Tr>
    );
};