import React, { useEffect, useMemo, useState } from "react";
import {
    Heading,
    Center,
    Stack,
    Text,
    Box,
    FormLabel,
    Flex,
    Icon,
    Badge,
    Divider,
    Button,
    Spacer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { ReportAPI } from "../../api/ReportAPI";
import { useParams } from "react-router-dom";
import { ReportEntry, reportTypeOptions, ReviewStatus, reviewStatusOptions, ReportTypeColor } from "../../models/report_entry";
import { to_oid } from "../../utils/ObjectId";
import { StudentReportEntry } from "./StudentReportEntry";
import SkeletonTable from "../table/TableSkeleton";
import { BlCard } from "../layout/Card";
import { FlexWrap } from "../layout/FlexWrap";
import { Select } from "chakra-react-select";
import { AiOutlineBook } from "react-icons/ai";
import { HiOutlineCalendar, HiOutlineDocument, HiOutlineFilter } from "react-icons/hi";
import { gerReportDate } from "../../models/semester";
import { CommentTemplateAPI } from "../../api/CommentTemplateAPI";
import { CommentTemplate } from "../../models/comment_template";
import { ReportType, reportTypeFromJSON, reportTypeToJSON } from "protobuffer-ts/dist/class_service/semester";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import { HomeroomCoursesList } from "../classes/HomeroomCoursesList";


export const HomeroomReportDashboard = React.memo(() => {
    let { homeroom_id } = useParams();
    const toast = useToast()

    const [homeroom, set_homeroom] = useState<Homeroom>();
    const [report_entries, set_report_entries] = useState<ReportEntry[]>([]);
    const [comment_templates, set_comment_templates] = useState<CommentTemplate[]>([]);

    const [selected_status, set_selected_status] = useState<ReviewStatus>();
	const [selected_report_type, set_selected_report_type] = useState<ReportType | undefined>(ReportType.Progress);

	const [loading, set_loading] = useState<boolean>(true);

    // Memoized filtered report entries based on the selected filters
    const filtered_entries = useMemo(() => {
        return report_entries.filter((entry) => {
            const matches_review_status = selected_status ? entry.review_status === selected_status : true;
            const matches_report_type = selected_report_type ? reportTypeFromJSON(entry.report_type) === selected_report_type : true;

            return matches_review_status && matches_report_type;
        });
    }, [report_entries, selected_status, selected_report_type]);


    useEffect(() => {
        const fetchAllData = async () => {
            try {
                set_loading(true);
    
                const [ homeroomRes, commentTemplatesRes ] = await Promise.all([
                    HomeroomAPI.get_homeroom(to_oid(homeroom_id!)),
                    CommentTemplateAPI.list()
                ]);

                set_homeroom(homeroomRes);
                set_comment_templates(commentTemplatesRes);

                const entries = await ReportAPI.get_homeroom_report_entries(homeroom_id!).catch(e => {
                    toast({
                        title: 'Failed to Retrive Entries.',
                        description: e.response.data,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    throw e;
                })

                set_report_entries(entries)
            } catch (e) {
                console.error('Error fetching data', e);
            } finally {
                set_loading(false);
            }
        };

        fetchAllData();
    }, [homeroom_id])

    const updateTemplates = async () => {
        try {
            const res = await CommentTemplateAPI.list()
    
            set_comment_templates(res)
        } catch (error) {}
    }


    return (
        <Stack gap={4}>
            <Center>
                <Heading size="md" mb="6">
                    Report Entry
                </Heading>
            </Center>

            <BlCard>
                <FlexWrap alignItems="start">
                    <Stack gap="2">
                        <Text fontSize="lg" fontWeight="bold" >
                            Report Information
                        </Text>

                        <Flex gap="8" wrap="wrap">
                            <Flex gap={2} align="center">
                                <Icon as={AiOutlineBook} boxSize={6} color="blue.500" />

                                <Text color="gray.600">Homeroom: <strong>{homeroom?.name}</strong></Text>
                            </Flex>

                            { selected_report_type && 
                                <Flex gap={2} align="center">
                                    <Icon as={HiOutlineDocument} boxSize={6} color="purple.500" />
                
                                    <Text color="gray.600">Report Type</Text>

                                    <Badge fontSize='xs' colorScheme={ReportTypeColor[selected_report_type]}>
                                        {reportTypeToJSON(selected_report_type)}
                                    </Badge>
                                </Flex>
                            }


                            { selected_report_type && 

                                <Flex gap={2} align="center">
                                    <Icon as={HiOutlineCalendar} boxSize={6} color="orange.500" />
                
                                    <Text color="gray.600">Due Date</Text>

                                    <Badge fontSize='xs' colorScheme="orange">
                                        {gerReportDate(homeroom?.semester?.reportLayout?.reportDates, selected_report_type, 'dueDate')}
                                    </Badge>
                                </Flex>
                            }

                            { selected_report_type && 
                                <Flex gap={2} align="center">
                                    <Icon as={HiOutlineCalendar} boxSize={6} color="red.500" />
                
                                    <Text color="gray.600">Distribution Date</Text>

                                    <Badge fontSize='xs' colorScheme="red">
                                        {gerReportDate(homeroom?.semester?.reportLayout?.reportDates, selected_report_type, 'distributionDate')}
                                    </Badge>
                                </Flex>
                            }
                        </Flex>
                    </Stack>

                    <Spacer />

                    <Popover placement="bottom-end">
                        <PopoverTrigger>
                            <Button 
                                leftIcon={<HiOutlineFilter/>}
                                color="purple"
                                bgColor="purple.50"
                                _hover={{ bg: "purple.100" }}
                            >
                                Filter                    
                            </Button>
                        </PopoverTrigger>

                        <PopoverContent >
                            <Box
                                as={Stack}
                                bg='bg-surface'
                                boxShadow={useColorModeValue('sm', 'sm-dark')}
                                borderRadius='lg'
                                px={{ base: '4', md: '6' }} 
                                py={{ base: '5', md: '6' }}
                            >
                                <FormLabel mb="0"> Report Type </FormLabel>

                                <Select
                                    isClearable
                                    value={reportTypeOptions(homeroom?.semester).find(option => option.value === selected_report_type)}
                                    options={reportTypeOptions(homeroom?.semester)}
                                    onChange={e => set_selected_report_type(e?.value)}
                                    placeholder="Select report type"
                                />

                                <Divider pt="2" />

                                <FormLabel mb="0"> Review Status </FormLabel>

                                <Select	
                                    isClearable
                                    value={reviewStatusOptions.find(option => option.value === selected_status)}
                                    options={reviewStatusOptions}
                                    onChange={e => set_selected_status(e?.value)}
                                    placeholder="Select review status"
                                />
                            </Box>
                        </PopoverContent>
                    </Popover>
                </FlexWrap>
            </BlCard>

            <HomeroomCoursesList base_url={'reports'} hide_heading={true} />

            {
                loading ? <SkeletonTable></SkeletonTable> 
                : 
                filtered_entries?.map(report => (
                    <StudentReportEntry
                        key={report._id.$oid}
                        report_entry={report}
                        comment_templates={comment_templates}
                        update_templates={updateTemplates}
                    />
                ))
            }
        </Stack>
    )
})
