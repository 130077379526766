import {Lookup} from "../../models/lookup";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import React, {useEffect, useRef, useState} from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Badge,
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Select,
    Skeleton,
    Stack,
    Switch,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {ObjectId} from "../../utils/ObjectId";
import {HomeroomAPI} from "../../api/HomeroomAPI";
import {Grades} from "../../models/student";
import {TeacherAPI} from "../../api/TeacherAPI";
import {Select as SearchSelect} from "chakra-react-select";
import {FiChevronDown} from "react-icons/fi";
import { Course } from "protobuffer-ts/dist/class_service/course";
import {CourseAPI} from "../../api/CourseAPI";

interface CreateHomeroomDialogProps {
    isOpen: boolean
    onClose: any
    teachers: Lookup[]
    semesters: Lookup[]
    clone_from?: Homeroom
}

export const CreateHomeroomDialog = React.memo(({ isOpen, onClose, clone_from, teachers, semesters }: CreateHomeroomDialogProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [loading, set_loading] = useState<boolean>(false);

    const [name, set_name] = useState<string>(clone_from ? clone_from.name : "");
    const [semester, set_semester] = useState<ObjectId>()
    const [teacher, set_teacher] = useState<ObjectId | undefined>(undefined);
    const [grade, set_grade] = useState<string | undefined>(clone_from ? clone_from.grade : undefined);

    const cancelRef = useRef<HTMLButtonElement>(null);

    const create = () => {
        if(name && teacher && grade && semester){
            set_loading(true)
            HomeroomAPI.create(name, semester, Grades.get(grade)!, [teacher]).then((res) => {
                set_loading(false)
                cancel()
                navigate("/class/homerooms/" + res.id!.id)
            })
        }else{
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const cancel = () => {
        set_name(clone_from?.name || "");
        set_teacher(undefined);
        set_grade(clone_from?.grade);

        onClose();
    };

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {clone_from ? `Cloning Homeroom From ${clone_from.name}` : 'Create Homeroom'}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {loading ? (
                            <Stack>
                                <Text>Please Wait... You will be redirected soon</Text>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack>
                        ) : (
                            <>
                                <FormControl id="name" isRequired>
                                    <FormLabel>Homeroom Name</FormLabel>
                                    <Input
                                        isRequired
                                        value={name}
                                        onChange={(value) => set_name(value.target.value)}
                                    />
                                </FormControl>
                                <br></br>
                                <FormControl id="semester" isRequired>
                                    <FormLabel>Semester</FormLabel>
                                    <SearchSelect
                                        name="flavors"
                                        options={semesters}
                                        placeholder="Select a semester..."
                                        onChange={(e: any) => {set_semester(e.value as ObjectId)}}
                                    />
                                </FormControl>
                                <br />
                                <FormControl id="teacher" isRequired>
                                    <FormLabel>Teacher</FormLabel>
                                    <SearchSelect
                                        name="flavors"
                                        options={teachers}
                                        placeholder="Select a teacher..."
                                        onChange={(e: any) => {set_teacher(e.value as ObjectId)}}
                                    />
                                </FormControl>
                                <br />
                                <FormControl id="grade" isRequired>
                                    <FormLabel>Grade</FormLabel>
                                    <Select isRequired
                                            value={grade}
                                            placeholder="Please Select the Grade"
                                            icon={<FiChevronDown/>}
                                            onChange={(value) => {
                                                set_grade(value.target.value)
                                            }}
                                    >
                                        {
                                            Array.from(Grades).map(([key, value]) => (
                                                <option value={key}>{value}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <br />
                            </>
                        )}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        {loading ? null : (
                            <>
                                <Button ref={cancelRef} onClick={cancel}>
                                    Cancel
                                </Button>
                                <Button colorScheme='green' onClick={create} ml={3}>
                                    Create
                                </Button>
                            </>
                        )}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
});


// interface CloneHomeroomDialogProps {
//     isOpen: boolean
//     onClose: any
//     teachers?: Lookup[]
//     semesters: Lookup[]
//     clone_from_homeroom: Homeroom
// }

// export const CloneHomeroomDialog = React.memo(({ isOpen, onClose, clone_from_homeroom, semesters }: CloneHomeroomDialogProps) => {
//     const toast = useToast();
//     const navigate = useNavigate();
//     const [loading, set_loading] = useState<boolean>(false);

//     const [teachers, set_teachers] = useState<any>();
//     const [name, set_name] = useState<string>(clone_from_homeroom ? clone_from_homeroom.name : "");
//     const [semester, set_semester] = useState<ObjectId>()
//     const [teacher, set_teacher] = useState<ObjectId | undefined>(undefined);
//     const [grade, set_grade] = useState<string | undefined>(clone_from_homeroom ? clone_from_homeroom.grade : undefined);
//     const [courses, setCourses] = useState<CourseClone[]>();
//     const [year, set_year] = useState<string>("")
//     const [google_classroom_to_be_cloned, set_google_classroom_to_be_cloned] = useState<boolean>(true);

//     const cancelRef = useRef<HTMLButtonElement>(null);

//     const create = () => {
//         if(name && teacher && grade && semester && year){
//             set_loading(true)
//             const courseClones: CourseClone[] = [];

//             if (courses) {
//                 for (const course of courses) {
//                     CourseAPI.get_report_layout(course.clone_from!).then((res) => {
//                         const body: CourseClone = {
//                             name: course.name,
//                             year: year,
//                             course_code: course.course_code,
//                             semester_id: semester,
//                             homeroom_id: undefined,
//                             teachers: [],
//                             char_limit: res.comment_char_limit,
//                             sections: res.sections,
//                             check_boxes: res.check_boxes,
//                         };

//                         if (google_classroom_to_be_cloned) {
//                             body.google_classroom_to_be_cloned = course.google_classroom_to_be_cloned;
//                         }

//                         courseClones.push(body);

//                         if (courseClones.length === courses.length) {

//                             let google_classroom_to_be_cloned_for_homeroom = undefined;
//                             if (google_classroom_to_be_cloned) {
//                                 google_classroom_to_be_cloned_for_homeroom = clone_from_homeroom?._id;
//                             }

//                             HomeroomAPI.clone(name, semester, Grades.get(grade)!, [teacher], courseClones, google_classroom_to_be_cloned_for_homeroom).then((homeroom) => {
//                                 toast({
//                                     title: 'Courses Cloned Successfully',
//                                     status: 'success',
//                                     duration: 5000,
//                                     isClosable: true,
//                                 });

//                                 set_loading(false);
//                                 cancel();
//                                 navigate("/class/homerooms/" + homeroom._id.$oid);
//                                 window.location.reload();
//                             }).catch((err) => {
//                                 toast({
//                                     title: 'Error.',
//                                     description: "Failed to clone courses: " + err.toString(),
//                                     status: 'error',
//                                     duration: 5000,
//                                     isClosable: true,
//                                 });

//                                 set_loading(false);
//                             });
//                         }
//                     });
//                 }
//             }
//         }else{
//             toast({
//                 title: 'Error.',
//                 description: "Please fill in all the required information",
//                 status: 'error',
//                 duration: 5000,
//                 isClosable: true,
//             });
//         }
//     };

//     const cancel = () => {
//         set_name("");
//         set_teacher(undefined);
//         set_grade(undefined);
//         set_google_classroom_to_be_cloned(true);

//         if (clone_from_homeroom) {
//             set_name(clone_from_homeroom.name);
//             set_grade(clone_from_homeroom.grade);
//         }

//         onClose();
//     };

//     useEffect(() => {
//         TeacherAPI.teachers_list(null, null, null).then((res) => {
//             let data = res!.teachers.map((e) => {
//                 return {
//                     value: e._id,
//                     label: e.profile.first_name + " " + e.profile.last_name,
//                 };
//             });
//             set_teachers(data);
//         });

//         HomeroomAPI.get_courses(clone_from_homeroom._id).then((res) => {
//             let data = res.map((course: Course) => ({
//                 clone_from: course._id,
//                 homeroom_id: clone_from_homeroom._id,
//                 name: course.name,
//                 semester_id: clone_from_homeroom.semester._id,
//                 course_code: course.course_code,
//                 year: year,
//                 teachers: [],
//                 char_limit: course.report_card_comment_char_limit,
//                 sections: [],
//                 check_boxes: course.report_check_boxes,
//                 google_classroom_to_be_cloned: course._id
//             }));

//             setCourses(data);
//         });
//     }, []);

//     return (
//         <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
//             <AlertDialogOverlay>
//                 <AlertDialogContent>
//                     <AlertDialogHeader fontSize='lg' fontWeight='bold'>
//                         {clone_from_homeroom ? `Cloning Homeroom From ${clone_from_homeroom.name}` : 'Create Homeroom'}
//                     </AlertDialogHeader>

//                     <AlertDialogBody>
//                         {loading ? (
//                             <Stack>
//                                 <Text>Please Wait... You will be redirected soon</Text>
//                                 <Skeleton height='20px' />
//                                 <Skeleton height='20px' />
//                                 <Skeleton height='20px' />
//                             </Stack>
//                         ) : (
//                             <>
//                                 <FormControl id="name" isRequired>
//                                     <FormLabel>Homeroom Name</FormLabel>
//                                     <Input
//                                         isRequired
//                                         value={name}
//                                         onChange={(value) => set_name(value.target.value)}
//                                     />
//                                 </FormControl>
//                                 <br></br>
//                                 <FormControl id="semester" isRequired>
//                                     <FormLabel>Semester</FormLabel>
//                                     <SearchSelect
//                                         name="flavors"
//                                         options={semesters}
//                                         placeholder="Select a semester..."
//                                         onChange={(e: any) => {set_semester(e.value as ObjectId)}}
//                                     />
//                                 </FormControl>
//                                 <br />
//                                 <FormControl id="teacher" isRequired>
//                                     <FormLabel>Teacher</FormLabel>
//                                     <SearchSelect
//                                         name="flavors"
//                                         options={teachers}
//                                         placeholder="Select a teacher..."
//                                         onChange={(e: any) => {set_teacher(e.value as ObjectId)}}
//                                     />
//                                 </FormControl>
//                                 <br />
//                                 <FormControl id="grade" isRequired>
//                                     <FormLabel>Grade</FormLabel>
//                                     <Select isRequired
//                                             value={grade}
//                                             placeholder="Please Select the Grade"
//                                             icon={<FiChevronDown/>}
//                                             onChange={(value) => {
//                                                 set_grade(value.target.value)
//                                             }}
//                                     >
//                                         {
//                                             Array.from(Grades).map(([key, value]) => (
//                                                 <option value={key}>{value}</option>
//                                             ))
//                                         }
//                                     </Select>
//                                 </FormControl>
//                                 <br/>
//                                 <FormControl id="year" isRequired>
//                                     <FormLabel>Year</FormLabel>
//                                     <Input isRequired
//                                            value={year}
//                                            onChange={(value) => {set_year(value.target.value)}}
//                                     />
//                                 </FormControl>
//                                 <br></br>
//                                 <FormControl id="courses" isRequired>
//                                     <FormLabel fontSize="xl" fontWeight="bold" mb={4}>Courses that will be cloned</FormLabel>
//                                     {courses && courses.length > 0 ? (
//                                         <VStack spacing={4} align="stretch">
//                                             {courses.map((course) => (
//                                                 <Box
//                                                     key={course.course_code}
//                                                     borderWidth="1px"
//                                                     borderRadius="lg"
//                                                     p={4}
//                                                     boxShadow="sm"
//                                                     _hover={{ boxShadow: "md" }}
//                                                 >
//                                                     <HStack justify="space-between">
//                                                         <Text fontWeight="medium">{course.name}</Text>
//                                                         <Badge colorScheme="blue" fontSize="0.8em" p={2} borderRadius="full">
//                                                             {course.course_code}
//                                                         </Badge>
//                                                     </HStack>
//                                                 </Box>
//                                             ))}
//                                         </VStack>
//                                     ) : (
//                                         <Box
//                                             p={4}
//                                             bg="gray.100"
//                                             borderRadius="md"
//                                             textAlign="center"
//                                         >
//                                             <Text color="gray.500">No courses available</Text>
//                                         </Box>
//                                     )}
//                                 </FormControl>
//                                 <br/>
//                                 <FormControl display="flex" alignItems="center" id="google_classroom_to_be_cloned">
//                                     <FormLabel htmlFor="google_classroom_to_be_cloned" mb="0">
//                                         Clone Google Classroom(s)
//                                     </FormLabel>
//                                     <Switch
//                                         id="google_classroom_to_be_cloned"
//                                         isChecked={google_classroom_to_be_cloned}
//                                         onChange={(e) => set_google_classroom_to_be_cloned(e.target.checked)}
//                                     />
//                                 </FormControl>
//                                 <br/>
//                             </>
//                         )}
//                     </AlertDialogBody>

//                     <AlertDialogFooter>
//                         {loading ? null : (
//                             <>
//                                 <Button ref={cancelRef} onClick={cancel}>
//                                     Cancel
//                                 </Button>
//                                 <Button colorScheme='green' onClick={create} ml={3}>
//                                     Create
//                                 </Button>
//                             </>
//                         )}
//                     </AlertDialogFooter>
//                 </AlertDialogContent>
//             </AlertDialogOverlay>
//         </AlertDialog>
//     );
// });