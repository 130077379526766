import { ObjectId } from "../utils/ObjectId";

export interface CommentTemplate {
    _id: ObjectId,
    organization: ObjectId,
    teacher: ObjectId,
    title: string,
    comment: string,
}

export enum TemplatePlaceholders {
    Name = 'student name',
    HeShe = 'he/she',
    HisHer = 'his/her',
    HimHers = 'him/hers'
  }