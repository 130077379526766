import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@chakra-ui/react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Stack,
    Text,
    Skeleton,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { SemesterAPI } from '../../api/SemesterAPI';
import { useNavigate } from "react-router-dom";
import FormInput from "../forms/FormInput";
import FormDatePicker from "../forms/FormDatePicker";
import { createSemesterSchema } from "../../validations/semester";
import { CreateSemester } from '../../models/semester';
interface CreateSemesterDialogProps {
    isOpen: boolean;
    onClose: () => void;
    cancelRef: any;
}

const CreateSemesterDialog: React.FC<CreateSemesterDialogProps> = ({ isOpen, onClose, cancelRef }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const { control, handleSubmit, reset, register, formState: { errors } } = useForm({
        resolver: yupResolver(createSemesterSchema),
        defaultValues: {
            name: '',
            start_date: new Date(),
            end_date: new Date(),
        },
    });

    const onSubmit = async (data: CreateSemester) => {
        setLoading(true);
        try {
            const res = await SemesterAPI.create(data);
            setLoading(false);
            onClose();
            navigate(`/admin_portal/semester/${res.id!.id}`);
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Error.',
                description: `Failed to create semester: ${error}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleCancel = () => {
        reset();
        onClose();
    };

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Create Semester
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {loading ? (
                            <Stack>
                                <Text>Please Wait... You will be redirected soon</Text>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack>
                        ) : (
                            <Stack onSubmit={handleSubmit(onSubmit)} as="form" gap="2">
                                <FormInput
                                    name="name"
                                    label="Name"
                                    register={register('name', {required: true})}
                                    error={errors.name?.message}
                                    isDisabled={false}
                                    isRequired
                                />
                                <FormDatePicker
                                    name="start_date"
                                    label="Start Date"
                                    control={control}
                                    error={errors.start_date?.message}
                                    isDisabled={false}
                                    isRequired
                                />

                                <FormDatePicker
                                    name="end_date"
                                    label="End Date"
                                    control={control}
                                    error={errors.end_date?.message}
                                    isDisabled={false}
                                    isRequired
                                />
                            </Stack>
                        )}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={handleCancel} isLoading={loading}>
                            Cancel
                        </Button>
                        <Button colorScheme='green' onClick={handleSubmit(onSubmit)} ml={3} isLoading={loading}>
                            Create
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default CreateSemesterDialog;