import { SubmissionsWithGClassWork } from '../models/gclass';
import {  States } from "protobuffer-ts/dist/class_service/gclass"
import { isAfter } from 'date-fns';
import { findTurnedInOrReturnedHistory } from '../utils/GClass';
import { proto_utils } from '../utils/proto_utils';

export const getSubmissionStatus = (sub: SubmissionsWithGClassWork) => {
    const turnedInHistory = findTurnedInOrReturnedHistory(sub);
    const hasBeenSubmitted = [States.STATES_TURNED_IN, States.STATES_RETURNED].includes(sub.submissionState);
    
    if (!sub.workNotSubmittedAndLate && !hasBeenSubmitted) {
        return 'notSubmitted';
    } else if (sub.workNotSubmittedAndLate && !hasBeenSubmitted) {
        return 'notSubmittedLate';
    } else if (turnedInHistory) {
        if (!sub.due_date || isAfter(sub.due_date, turnedInHistory.stateHistory?.stateTimestamp || new Date(''))) {
            return 'submittedOnTime';
        } else {
            return 'submittedLate';
        }
    }
    return '';
};


export const useSubmissionCounts = (submissions: SubmissionsWithGClassWork[]) => {

    let notSubmittedCount = 0;
    let notSubmittedLateCount = 0;
    let submittedOnTimeCount = 0;
    let submittedLateCount = 0;

    submissions.forEach(sub => {
        const status = getSubmissionStatus(sub);

        if (status === 'notSubmitted') notSubmittedCount++;
        if (status === 'notSubmittedLate') notSubmittedLateCount++;
        if (status === 'submittedOnTime') submittedOnTimeCount++;
        if (status === 'submittedLate') submittedLateCount++;
    });

    return {
        notSubmittedCount,
        notSubmittedLateCount,
        submittedOnTimeCount,
        submittedLateCount,
    };
}
