import {ObjectId} from "../utils/ObjectId";
import { DateTime } from "../utils/DateTime";

export class Notification {
    _id: ObjectId;
    title: string;
    body: string;
    created: string;
    created_by: string;
    created_by_id: ObjectId;
    notification_type: string[];
    notification_destination_user: string;
    notification_destination_user_id: ObjectId;
    date_to_send: DateTime;

    constructor(data: any) {
        this._id = data._id;
        this.title = data.title;
        this.body = data.body;
        this.created = data.created;
        this.created_by = data.created_by;
        this.created_by_id = data.created_by_id;
        this.notification_type = data.notification_type;
        this.notification_destination_user = data.notification_destination_user;
        this.notification_destination_user_id = data.notification_destination_user_id;
        this.date_to_send = new DateTime(data.date_to_send);
    }
}