import {
    Button,
    Stack,
    useToast,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialog,
    FormControl,
    FormLabel,
    Input,
    Skeleton,
    Text,
    Switch,
} from '@chakra-ui/react';
import {
    Select as SearchSelect,
} from "chakra-react-select";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CourseAPI } from "../../api/CourseAPI";
import { Homeroom } from 'protobuffer-ts/dist/class_service/homeroom';
import { Lookup } from '../../models/lookup';
import { ObjectId } from "../../utils/ObjectId";
import { proto_utils } from '../../utils/proto_utils';

interface CreateCourseDialogProps {
    isOpen: boolean
    onClose: any
    teachers?: Lookup[]
    semesters: Lookup[]
    homeroom?: Homeroom
}

export const CreateCourseDialog = memo(({isOpen, onClose, teachers, semesters, homeroom}: CreateCourseDialogProps) => {
    const toast = useToast()
    const navigate = useNavigate()
    const [loading, set_loading] = useState<boolean>(false);

    const [name, set_name] = useState<string>("");
    const [course_code, set_course_code] = useState<string>()
    const [teacher, set_teacher] = useState<ObjectId>();
    const [semester_id, set_semester_id] = useState<ObjectId>()
    const [gclass_create, set_gclass_create] = useState<boolean>(!homeroom);

    const cancelRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        set_gclass_create(!homeroom);
    }, [homeroom]);

    const create = async () => {
        // check if there is homeroom, assign its semester_id
        if(name && teacher && course_code && semester_id) {
            set_loading(true)

            const submitFunction = homeroom ? CourseAPI.homeroom_create : CourseAPI.create

            const body = {
                name,
                course_code,
                semester_id,
                homeroom_id: homeroom ? proto_utils.from_proto_object_id(homeroom!.id!) : undefined,
                teachers: [teacher],
                gclass_create: gclass_create
            }

            submitFunction(body).then((res) => {
                set_loading(false)
                cancel()
                navigate("/class/courses/" + res.id!.id)
            })
        }else{
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const cancel = () => {
        set_name( "");
        set_course_code( "");
        set_teacher(undefined);
        if (!homeroom) set_semester_id(undefined);

        onClose();
    };

    useEffect(() => {
        if(homeroom){
            // update semester id if homeroom is passed
            set_semester_id(proto_utils.from_proto_object_id(homeroom.semester!.id!))
        }
    }, [homeroom])

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Create Course
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {loading ?
                            <Stack>
                                <Text>Please Wait... You will be redirected soon</Text>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack>
                            : (
                                <Stack gap="4">
                                    <FormControl id="name" isRequired>
                                        <FormLabel>Course Name</FormLabel>

                                        <Input isRequired
                                                value={name}
                                                onChange={(e) => set_name(e.target.value)}
                                        />
                                    </FormControl>

                                    {
                                        !homeroom && (
                                            <>
                                                <FormControl id="semester" isRequired>
                                                    <FormLabel>Semester</FormLabel>

                                                    <SearchSelect
                                                        name="flavors"
                                                        options={semesters}
                                                        placeholder="Select a semester..."
                                                        onChange={(e: any) => {set_semester_id(e.value as ObjectId)}}
                                                    />
                                                </FormControl>
                                            </>
                                        )
                                    }
                                    
                                    <FormControl id="teacher" isRequired>
                                        <FormLabel>Teacher</FormLabel>
                                        <SearchSelect
                                            name="flavors"
                                            options={teachers}
                                            placeholder="Select a teacher..."
                                            onChange={(e: any) => {set_teacher(e.value as ObjectId)}}
                                        />
                                    </FormControl>

                                    <FormControl id="course_code" isRequired>
                                        <FormLabel>Course Code</FormLabel>
                                        <Input isRequired
                                            value={course_code}
                                            onChange={(value) => {set_course_code(value.target.value)}}
                                        />
                                    </FormControl>

                                    <FormControl id="gclass_create" isRequired>
                                        <FormLabel htmlFor="create-gclass" mb="0">
                                            {gclass_create ? "Create Google Class" : "Create Google Class Disabled"}
                                        </FormLabel>
                                        <Switch
                                            id="create-gclass"
                                            isChecked={gclass_create}
                                            onChange={() => set_gclass_create(!gclass_create)}
                                            colorScheme="teal"
                                        />
                                    </FormControl>
                                </Stack>
                            )
                        }
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={cancel}>
                            Cancel
                        </Button>

                        <Button colorScheme='green' ml={3} isLoading={loading} onClick={create}>
                            Create
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
})