import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { Student } from '../../../models/student';
import { useLocation } from 'react-router-dom';
import { HomeroomObj } from '../../../models/homeroom';
import { ObjectId } from '../../../utils/ObjectId';
import { useTeacherCell } from '../../../hooks/useTeacherCell';
import { Semester } from "protobuffer-ts/dist/class_service/semester";
import { useChipCell } from '../../../hooks/useChipCell';
import { StudentAPI } from '../../../api/StudentAPI';
import { Column, TableContent } from '../../table/TableContent';
import { useTeachersLookup } from '../../../hooks/useTeachersLookup';
import { FormLabel } from '@chakra-ui/react';
import { BlCard } from '../../layout/Card';
import { useUser } from '../../../hooks/useUser';

interface Card {
    student: Student
}

export const StudentHomeroomList = React.memo(({student}: Card) => {
	const [homerooms_data, set_homerooms_data] = React.useState<HomeroomObj[]>();

    const [loading, setLoading] = React.useState<boolean>(true);

	// https://albassem.atlassian.net/browse/BL-465
	//! show teacher name in parent portal
	const { userIsTeacher } = useUser() 

    const location = useLocation();
    const { teachers } = useTeachersLookup();

    const columns = React.useMemo(() => [
		{
			Header: "Name",
			accessor: "name",
		},
		userIsTeacher && {
            Header: "Teacher Name",
            accessor: "teacher_ids",
            Cell: (data: ObjectId[]) => useTeacherCell(data, teachers)
        },
		{
			Header: "Semester",
			accessor: "semester",
			Cell: (data: Semester) => useChipCell(data.name, 'blue')
		},
		{                
			Header: "Grade",
			accessor: "grade",
			Cell: (data: string) => useChipCell(data, 'purple')
		}
    ].filter(Boolean) as Column[], [teachers, userIsTeacher])

    React.useEffect(() => {
		setLoading(true);
        
        StudentAPI.get_homerooms(student._id)
            .then((res) => {
                let homerooms = res.map((homeroom) => ({
                    ...homeroom, 
                    teachers: homeroom.teacherIds,
                    link: location.pathname + "/homeroom/" + homeroom.id!.id
                }))

                set_homerooms_data(homerooms)
            }).finally(() => {
                setLoading(false);
            })
    }, [location.pathname, student._id])
    
    return (
		homerooms_data?.length ? 
		<BlCard>
			<FormLabel fontSize={"lg"} fontWeight="bold">
				Homerooms
			</FormLabel>

			<TableContent
				loading={loading} 
				data={homerooms_data} 
				columns={columns} 
			/>
		</BlCard> : <></>
    )
})