import React, {useEffect} from "react"
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
    FormLabel,
    Stack,
    Badge,
    HStack,
    Heading,
} from '@chakra-ui/react';
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { TableContent } from "../table/TableContent";
import { TeacherAPI } from "../../api/TeacherAPI";
import { Lookup } from "../../models/lookup";
import { useTeacherCell } from "../../hooks/useTeacherCell";
import { BlCard } from "../layout/Card";
import {useTeachersLookup} from "../../hooks/useTeachersLookup";

interface Card {
    base_url: string,
    hide_heading?: boolean
}

export const HomeroomCoursesList = ({base_url='class' , hide_heading= false}: Card) =>  {
    let { homeroom_id } = useParams();

    const [homeroom, set_homeroom] = useState<Homeroom>();
    const [courses, set_courses] = useState<Course[]>();
    const { teachers } = useTeachersLookup();

    const courseTableColumns = [
        {Header: "Name", accessor: "name"},

        {
            Header: "Teachers",
            accessor: "teacher_ids",
            Cell: (data:  ObjectId[]) => useTeacherCell(data, teachers)
        },
    ]

    const fetchHomeroom = () => {
        HomeroomAPI.get_homeroom(to_oid(homeroom_id!)).then((res) => {
            set_homeroom(res)
        })
    }

    useEffect(() => {
        if (homeroom_id) fetchHomeroom()

        HomeroomAPI.get_courses(to_oid(homeroom_id!)).then((res) => {
            let courses = res.map((course) => {
                return {
                    ...course,
                    link: '/' + base_url + '/courses/'+ course.id?.id
                }
            })

            set_courses(courses)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            { !hide_heading &&
            <HStack align={"center"} mb={8}>
                <Heading as="h1" size="md" fontWeight={"bold"}>
                    {homeroom?.name}
                </Heading>

                <Badge colorScheme={homeroom?.archived ? "red" : "green"}>
                    {homeroom?.archived ? "Archived" : "Active"}
                </Badge>
            </HStack>
            }

            <Stack gap="4">
                {
                    !!courses?.length &&
                    <BlCard>
                        <HStack>
                            <FormLabel fontSize={"lg"} fontWeight="bold">
                                Courses
                            </FormLabel>
                        </HStack>

                        <TableContent
                            data={courses}
                            columns={courseTableColumns}
                        />
                    </BlCard>
                }
            </Stack>
        </>
    );
};