import { Course } from "protobuffer-ts/dist/class_service/course";
import { ReportType, reportTypeFromJSON, reportTypeToJSON, Semester, SemesterReportLayout } from "protobuffer-ts/dist/class_service/semester";
import { ObjectId } from "../utils/ObjectId"

// Remove strings and unrecognized value
export const reportTypeValues = Object.values(ReportType)
  .filter((type) => typeof type === 'number' && type !== -1) as ReportType[];

export const reportTypeOptions = (semester: Semester | undefined) => {
    if (semester?.reportLayout?.includeProgressReportCards) {
        return reportTypeValues.map((type) => ({
            value: type,
            label: reportTypeToJSON(type as ReportType),
        }));    
    } else {
        return reportTypeValues.map((type) => ({
            value: type,
            label: reportTypeToJSON(type as ReportType),
        })).filter(e => e.value != ReportType.Progress)
    }
}

export enum ReviewStatus {
    NotFilled = "NotFilled",
    Filled = "Filled",
    ChangesRequested = "ChangesRequested",
    Completed = "Completed",
    Approved = "Approved",
    Published = "Published"
}

export const reviewStatusOptions = Object.values(ReviewStatus).map((status) => ({
    value: status,
    label: status,
}));

export interface CheckBox {
    check_box_id: ObjectId,
    state: boolean,
}

interface Section {
    layout_id: ObjectId,
    mark: string,
}

interface LeaningSkill { 
    learning_skill_id: ObjectId,
    mark: string,
}

export class ReportEntry {
    _id: ObjectId
    organization: ObjectId
    student: ObjectId
    course?: ObjectId | null
    homeroom?: ObjectId | null

    report_type: ReportType
    review_status: ReviewStatus
    comment?: string | null
    requested_changes: string
    
    checkboxes: CheckBox[]
    sections: Section[]
    learning_skills: LeaningSkill[]

    class_name: string
    semester_report_layout: SemesterReportLayout

    constructor(data : any) {
        this._id = data._id
        this.organization = data.organization
        this.student = data.student
        this.course = data.course
        this.homeroom = data.homeroom
    
        this.report_type = reportTypeFromJSON(data.report_type)
        this.review_status = data.review_status
        this.comment = data.comment
        this.requested_changes = data.requested_changes
        
        this.checkboxes = data.checkboxes
        this.sections = data.sections
        this.learning_skills = data.learning_skills

        this.class_name = data.class_name
        this.semester_report_layout = data.semester_report_layout
    }
}   

export type ReportEntryUpdate = Omit<ReportEntry, 'class_name' | 'semester_report_layout'>;

export interface RequestChangesReportEntry {
    report_id: ObjectId,
    requested_changes: String,
}

export interface UnpublishReportEntry {
    report_id: ObjectId,
    requested_changes: String,
}

export const ReportTypeColor: Record<ReportType, string> = {
    [ReportType.Progress]: 'blue',
    [ReportType.Midterm]: 'green',
    [ReportType.Final]: 'purple',
    [ReportType.UNRECOGNIZED]: 'gray'
};


export const ReviewStatusColor: Record<ReviewStatus, string> = {
    [ReviewStatus.NotFilled]: 'gray',
    [ReviewStatus.Filled]: 'blue',
    [ReviewStatus.ChangesRequested]: 'orange',
    [ReviewStatus.Completed]: 'purple',
    [ReviewStatus.Approved]: 'green',
    [ReviewStatus.Published]: 'purple'
};


export const ElementaryMarksOptions = [
    "Progressing with Difficulty",
    "Progressing Well",
    "Progressing Very Well",
]