// CreateEvaluation.tsx

import React, { useEffect, useState, useRef } from "react";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    VStack,
    useColorModeValue,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    Skeleton,
    AlertDialogFooter,
    Select,
} from "@chakra-ui/react";
import { Evaluation } from "../../../models/evaluation";
import { new_oid, ObjectId } from "../../../utils/ObjectId";
import { EvaluationAPI } from "../../../api/EvaluationAPI";
import { MarkCategoriesAPI } from "../../../api/MarkCategoryAPI";
import { MarkCategory } from "../../../models/mark_category";
import { Select as SearchSelect } from "chakra-react-select";
import { DateTime } from "../../../utils/DateTime";

interface CreateEvaluationDialogCard {
    class_id: ObjectId;
    isOpen: boolean;
    onClose: () => void;
    cancelRef: React.RefObject<HTMLButtonElement>;
}

export const CreateEvaluation = ({
                                     class_id,
                                     isOpen,
                                     onClose,
                                     cancelRef,
                                 }: CreateEvaluationDialogCard) => {
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [evaluation, setEvaluation] = useState<Evaluation>({
        _id: new_oid(),
        class_id: class_id,
        name: "",
        total: 0,
        weight: 0,
        date: DateTime.from_date(new Date()),
        mark_category: undefined,
    });
    const [markCategories, setMarkCategories] = useState<MarkCategory[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const fetchMarkCategories = async () => {
        try {
            const categories = await MarkCategoriesAPI.get_mark_category_list_from_class(class_id);
            setMarkCategories(categories || []);
        } catch (error) {
            console.error("Failed to fetch mark categories:", error);
            toast({
                title: "Error",
                description: "Failed to fetch mark categories.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchMarkCategories();
        }
    }, [isOpen]);

    const requiredInfo = () => {
        return (
            evaluation.name.trim() !== "" &&
            evaluation.total > 0 &&
            evaluation.weight > 0 &&
            evaluation.mark_category !== undefined
        );
    };

    const handleCreate = async () => {
        if (requiredInfo()) {
            setIsSubmitting(true);
            try {
                await EvaluationAPI.create_evaluation(evaluation);
                toast({
                    title: "Success",
                    description: "Evaluation created successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
            } catch (error) {
                console.error("Failed to create evaluation:", error);
                toast({
                    title: "Error",
                    description: "Failed to create evaluation.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
                resetForm();
            }
        } else {
            toast({
                title: "Error",
                description: "Please fill in all the required information.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const resetForm = () => {
        setEvaluation({
            _id: new_oid(),
            class_id: class_id,
            name: "",
            total: 0,
            weight: 0,
            date: DateTime.from_date(new Date()),
            mark_category: undefined,
        });
    };

    const isCreateDisabled = markCategories.length === 0;

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Create Evaluation
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {isCreateDisabled && (
                            <Alert status="warning" mb={4}>
                                <AlertIcon />
                                <Box flex="1">
                                    <AlertTitle>No Mark Categories Available</AlertTitle>
                                    <AlertDescription>
                                        Please create a mark category before creating an evaluation.
                                    </AlertDescription>
                                </Box>
                            </Alert>
                        )}
                        {loading ? (
                            <Stack>
                                <Skeleton height="20px" />
                                <Skeleton height="20px" />
                                <Skeleton height="20px" />
                            </Stack>
                        ) : (
                            <CreateEvaluationDialog
                                evaluation={evaluation}
                                setEvaluation={setEvaluation}
                                markCategories={markCategories}
                                isCreateDisabled={isCreateDisabled}
                            />
                        )}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose} isDisabled={isSubmitting}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="green"
                            onClick={handleCreate}
                            ml={3}
                            isLoading={isSubmitting}
                            isDisabled={isCreateDisabled || isSubmitting}
                        >
                            Create
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

interface CreateEvaluationDialogProps {
    evaluation: Evaluation;
    setEvaluation: React.Dispatch<React.SetStateAction<Evaluation>>;
    markCategories: MarkCategory[];
    isCreateDisabled: boolean;
}

export const CreateEvaluationDialog = ({
                                           evaluation,
                                           setEvaluation,
                                           markCategories,
                                           isCreateDisabled,
                                       }: CreateEvaluationDialogProps) => {
    const updateEvaluationState = (name: string, value: any) => {
        setEvaluation((prevState) => ({ ...prevState, [name]: value }));
    };

    return (
        <Stack spacing={4}>
            <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                    value={evaluation.name}
                    onChange={(e) => {
                        updateEvaluationState("name", e.target.value);
                    }}
                    isDisabled={isCreateDisabled}
                />
            </FormControl>

            <FormControl id="mark_category" isRequired>
                <FormLabel>Mark Category</FormLabel>
                <Select
                    placeholder="Select Mark Category"
                    onChange={(e) => {
                        updateEvaluationState("mark_category", { $oid: e.target.value });
                    }}
                    value={evaluation.mark_category?.$oid || ""}
                    isDisabled={isCreateDisabled}
                >
                    {markCategories.map((category) => (
                        <option key={category._id.$oid} value={category._id.$oid}>
                            {category.name}
                        </option>
                    ))}
                </Select>
            </FormControl>

            <FormControl id="total" isRequired>
                <FormLabel>Total Marks</FormLabel>
                <Input
                    type="number"
                    value={evaluation.total}
                    onChange={(e) => {
                        updateEvaluationState("total", Number(e.target.value));
                    }}
                    isDisabled={isCreateDisabled}
                />
            </FormControl>

            <FormControl id="weight" isRequired>
                <FormLabel>Weight</FormLabel>
                <Input
                    type="number"
                    value={evaluation.weight}
                    onChange={(e) => {
                        updateEvaluationState("weight", Number(e.target.value));
                    }}
                    isDisabled={isCreateDisabled}
                />
            </FormControl>
        </Stack>
    );
};