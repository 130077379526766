import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    FormLabel,
    Stack,
    Text,
    useToast,
    HStack,
    Spacer,
    Heading,
    SimpleGrid,
    Flex,
    Divider,
    IconButton,
    Collapse,
    Box,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { new_oid_proto, ObjectId, to_oid } from "../../utils/ObjectId";
import { FiArchive} from "react-icons/fi";
import { ReportType, reportTypeToJSON, Semester } from "protobuffer-ts/dist/class_service/semester";
import { CourseAPI } from "../../api/CourseAPI";
import {SemesterAPI} from "../../api/SemesterAPI";
import { TableContent } from "../table/TableContent";
import { AddIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, MinusIcon } from "@chakra-ui/icons";
import { UserRole } from "../../api/UserApi";
import LocalStorageService from "../../api/LocalStorageService";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { updateSemesterSchema } from "../../validations/semester";
import FormInput from "../forms/FormInput";
import FormDatePicker from "../forms/FormDatePicker";
import { TeacherAPI } from "../../api/TeacherAPI";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import { getLookupLabel } from "../../models/lookup";
import { BlCard } from "../layout/Card";
import { proto_utils } from "../../utils/proto_utils";
import { learningSkillsOptions, SemesterUpdate } from "../../models/semester";
import FormSwitch from "../forms/FormSwitch";
import { DeleteDialog } from "../dialog/DeleteDialog";
import { useChipCell } from "../../hooks/useChipCell";
import FormSelect from "../forms/FormSelect";

export const SemesterDashboard = React.memo(() => {
    let { semester_id } = useParams();

    interface CourseObj {
        id: string,
        archived: boolean,
        name: string,
        link?: string
        status?: string
    }

    const toast = useToast();
    const [semester, set_semester] = useState<Semester>();
    const [courses, set_courses] = useState<CourseObj[]>([]);
    const [coursesCount, setCoursesCount] = useState<number>(0);
    const [homerooms, set_homerooms] = useState<CourseObj[]>([]);
    const [homeroomsCount, setHomeroomsCount] = useState<number>(0);
    const [teachers, set_teachers] = useState<any[]>([]);


    const [isLearningSkillsOpen, setIsLearningSkillsOpen] = useState<boolean>(true);
    const [isLearningSkillAlertOpen, setIsLearningSkillAlertOpen] = useState<boolean>(false);
    const [removeIndex, setRemoveIndex] = useState<number>();

    const [loading, setLoading] = useState<boolean>(false);
    const [archiveLoading, setArchiveLoading] = useState<boolean>(false);

    const user = LocalStorageService.getInstance().getUser();
    const canModifySemester = user?.has_role(UserRole.Can_Modify_Semester);

    const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
        resolver: yupResolver(updateSemesterSchema),
    });

    const { fields: LearningSkillsField, append: appendLearningSkill, remove: removeLearningSkill } = useFieldArray({
        control,
        name: "reportLayout.learningSkills",
    });

    const { fields: ReportDatesField } = useFieldArray({
        control,
        name: "reportLayout.reportDates",
    });

    const toggleLearningSkillsSection = () => {
        setIsLearningSkillsOpen(!isLearningSkillsOpen);
    };

    const handleRemoveLearningSkill = ( index: number) => {
        setRemoveIndex(index);
        setIsLearningSkillAlertOpen(true);
    };

    const confirmLearningSkillRemove = () => {
        removeLearningSkill(removeIndex!);
        setIsLearningSkillAlertOpen(false);
    };

    useEffect(() => {
        const getSemester = async () => {
            try {
                const res = await SemesterAPI.get_semester(to_oid(semester_id!));

                const transformedSemester = {
                    ...res,
                    reportLayout: res.reportLayout ? {
                        ...res.reportLayout,
                        reportDates: res.reportLayout.reportDates.map((reportDate: any) => ({
                            ...reportDate,
                        })),
                    } : {
                        commentCharLimit: 800,  // Provide default structure for `reportLayout` if undefined
                        includeProgressReportCards: true,
                        showCreditsEarnedEntry: false,
                        learningSkills: [],
                        reportDates: [
                            { reportType: ReportType.Progress, dueDate: new Date(), distributionDate: new Date() },
                            { reportType: ReportType.Midterm, dueDate: new Date(), distributionDate: new Date() },
                            { reportType: ReportType.Final, dueDate: new Date(), distributionDate: new Date() },
                        ],
                    }
                };
    
                set_semester(transformedSemester);
                reset(transformedSemester);
            } catch (error) {
                toast({
                    title: 'Error',
                    description: "Failed to fetch semester data",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        getSemester();
    }, [semester_id, reset, toast]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const coursesRes = await CourseAPI.course_list(
                    null, // per_page
                    null, // page
                    undefined, // name_search
                    undefined, // archived
                    undefined, // teacher_id
                    true,
                    to_oid(semester_id!), // semester
                );
                if (coursesRes) {
                    let courses = coursesRes.courses.map((course) => {
                        return {
                            ...course,
                            id: course._id.$oid,
                            link: '/class/courses/'+ course._id.$oid
                        }
                    })
                    set_courses(courses);
                    setCoursesCount(coursesRes.courses_count);
                }
            } catch (error) {
                toast({
                    title: 'Error',
                    description: "Failed to fetch courses",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        const fetchHomerooms = async () => {
            try {
                const homeroomsRes = await HomeroomAPI.homeroom_list(
                    null, // per_page
                    null, // page
                    undefined, // name_search
                    undefined, // archived
                    undefined, // teacher_id
                    to_oid(semester_id!), // semester
                );
                if (homeroomsRes) {
                    let homerooms = homeroomsRes.homerooms.map((homeroom) => {
                        return {
                            ...homeroom,
                            id: homeroom._id.$oid,
                            link: '/class/homerooms/' + homeroom._id.$oid
                        }
                    })
                    set_homerooms(homerooms);
                    setHomeroomsCount(homeroomsRes.homerooms_count);
                }
            } catch (error) {
                toast({
                    title: 'Error',
                    description: "Failed to fetch courses",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchCourses();
        fetchHomerooms();
    }, [semester_id, toast]);

    useEffect(()=>{
        TeacherAPI.teachers_list(null, null, null)
            .then((res) => {
                let data = res!.teachers.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.profile.first_name + " " + e.profile.last_name
                    }
                })
                set_teachers(data)
            })
    },[])


    const updateSemester = async (data: any) => {
        const transformedData = new SemesterUpdate(data)
        setLoading(true);
        try {
            const updatedSemester = await SemesterAPI.update_semester(transformedData);
            set_semester(updatedSemester);
    
            toast({
                title: 'Success',
                description: "Semester updated successfully",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: "Failed to update semester",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const archiveSemester = () => {
        setArchiveLoading(true);
        SemesterAPI.archive_semester(proto_utils.from_proto_object_id(semester!.id!))
            .then(() => {
                toast({
                    title: 'Success',
                    description: "Semester Archived",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch(() => {
                toast({
                    title: 'Error',
                    description: "Failed to Archive Semester",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .finally(() => setArchiveLoading(false));
    };
    return (
        <Stack gap="4">
            <HStack align={"center"} mb={8}>
                <Heading as="h1" size="md" fontWeight={"bold"}>
                    {semester?.name} Semester
                </Heading>
                <Spacer/>
            </HStack>

            <BlCard
                as="form"
                onSubmit={handleSubmit(updateSemester)}
            >

                <HStack>
                    <FormLabel fontSize={"lg"} fontWeight="bold">
                        Semester Information
                    </FormLabel>
                    <Spacer/>
                    {canModifySemester && (
                        <Button
                            isLoading={loading}
                            colorScheme='green'
                            leftIcon={<CheckIcon/>}
                            type="submit"
                        >
                            Update
                        </Button>
                    )}
                </HStack>

                <SimpleGrid columns={{sm: 1, md: 3}} spacing={10}>

                    <FormInput
                        name="name"
                        label="Name"
                        register={register('name', {required: true})}
                        error={errors.name?.message}
                        isDisabled={!canModifySemester}
                        isRequired
                    />
                    <FormDatePicker
                        name="startDate"
                        label="Start Date"
                        control={control}
                        error={errors.startDate?.message}
                        isDisabled={!canModifySemester}
                        isRequired
                    />

                    <FormDatePicker
                        name="endDate"
                        label="End Date"
                        control={control}
                        error={errors.endDate?.message}
                        isDisabled={!canModifySemester}
                        isRequired
                    />

                </SimpleGrid>

                <Divider />

                <FormLabel fontSize={"lg"} fontWeight="bold">
                    Report Information
                </FormLabel>

                <Stack gap="2">
                    <FormInput
                        name="commentCharLimit"
                        label="Comment Character Limit"
                        register={register("reportLayout.commentCharLimit")}
                        error={errors.reportLayout?.commentCharLimit?.message}
                        isRequired
                    />

                    <SimpleGrid columns={{sm: 1, md: 2}} gap="6">
                        <BlCard boxShadow="none" bgColor="gray.50" border="2px dashed" borderColor="gray.300">
                            <Flex align="center" gap="4">
                                <FormSwitch 
                                    name="reportLayout.includeProgressReportCards" 
                                    control={control}
                                />

                                <FormLabel>Include Progress Report Cards</FormLabel>

                                <span>{errors.reportLayout?.includeProgressReportCards?.message}</span>
                            </Flex>
                        </BlCard>

                        <BlCard boxShadow="none" bgColor="gray.50" border="2px dashed" borderColor="gray.300">
                            <Flex align="center" gap="4">
                                <FormSwitch 
                                    name="reportLayout.showCreditsEarnedEntry" 
                                    control={control}
                                />

                                <FormLabel>Show Credits Eearned Entry</FormLabel>

                                <span>{errors.reportLayout?.showCreditsEarnedEntry?.message}</span>
                            </Flex>
                        </BlCard>
                    </SimpleGrid>

                    <Divider />

                    <Text fontSize={"md"} fontWeight="bold">
                        Due Dates
                    </Text>

                    <Box p="4" borderWidth="1px" borderRadius="lg" bgColor={"gray.50"}>
                        <Stack gap="4">

                        {
                            ReportDatesField.map((reportDate, index)=> (
                                <Grid templateColumns={{md: 'repeat(12, 1fr)'}} gap={10} key={index}>
                                    <GridItem colSpan={{md: 2}} as={Flex} alignItems="center">
                                        <FormLabel>{reportTypeToJSON(reportDate.reportType)} Report Type</FormLabel>
                                    </GridItem>

                                    <GridItem colSpan={{md: 5}}>
                                        <FormDatePicker
                                            label="Due Date"
                                            name={`reportLayout.reportDates.${index}.dueDate`}
                                            control={control}
                                            error={errors.reportLayout?.reportDates?.[index]?.dueDate?.message}
                                            isDisabled={!canModifySemester}
                                            isRequired
                                        />
                                    </GridItem>
            
                                    <GridItem colSpan={{md: 5}}>
                                        <FormDatePicker
                                            label="Distribution Date"
                                            name={`reportLayout.reportDates.${index}.distributionDate`}
                                            control={control}
                                            error={errors.reportLayout?.reportDates?.[index]?.distributionDate?.message}
                                            isDisabled={!canModifySemester}
                                            isRequired
                                        />
                                    </GridItem>
                                </Grid>
                            ))
                        }
                        </Stack>
                    </Box>

                    <Divider />

                    <HStack>
                        <Text fontSize={"md"} fontWeight="bold">
                            Learning Skills
                        </Text>

                        <Spacer />

                        <IconButton
                            aria-label="Toggle Sections"
                            icon={isLearningSkillsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            onClick={toggleLearningSkillsSection}
                            variant="outline"
                            size="sm"
                        />
                    </HStack>

                    <Collapse in={isLearningSkillsOpen}>
                        <Box p="4" borderWidth="1px" borderRadius="lg" bgColor={"gray.50"}>
                            <Stack gap="4">
                                {LearningSkillsField.map((skill, index) => (
                                    <Grid templateColumns={{md: 'repeat(12, 1fr)'}} key={skill.id!.id} gap="2">
                                        <GridItem colSpan={{md: 3}}>
                                            <FormSelect
                                                name={`reportLayout.learningSkills.${index}.title`}
                                                label="Title"
                                                options={learningSkillsOptions}
                                                register={register(`reportLayout.learningSkills.${index}.title`)}
                                                error={errors.reportLayout?.learningSkills?.[index]?.title?.message}
                                                isRequired
                                            />
                                        </GridItem>

                                        <GridItem colSpan={{md: 9}}>
                                            <HStack align={"flex-end"}>
                                                <FormInput
                                                    label="Description"
                                                    name={`reportLayout.learningSkills.${index}.description`}
                                                    register={register(`reportLayout.learningSkills.${index}.description`)}
                                                    error={errors.reportLayout?.learningSkills?.[index]?.description?.message}
                                                    isRequired
                                                />

                                                <IconButton
                                                    aria-label="Remove skill"
                                                    icon={<MinusIcon />}
                                                    variant="outline"
                                                    borderColor="red.600"
                                                    textColor="red.600"
                                                    size="sm"
                                                    onClick={() => handleRemoveLearningSkill(index)}
                                                />
                                            </HStack>
                                        </GridItem>

                                        
                                    </Grid>
                                ))}
                                <Button
                                    aria-label="Add skill"
                                    leftIcon={<AddIcon />}
                                    variant="outline"
                                    border="1px dashed"
                                    borderColor="gray.400"
                                    size="sm"
                                    alignSelf="start"
                                    onClick={() => appendLearningSkill({
                                        id: new_oid_proto(),
                                        title: '',
                                        description: ''
                                    })}
                                >
                                    Add Learning Skills
                                </Button>
                            </Stack>
                        </Box>
                    </Collapse>

                    <DeleteDialog
                        item="skill"
                        isOpen={isLearningSkillAlertOpen}
                        action={confirmLearningSkillRemove}
                        onClose={() => setIsLearningSkillAlertOpen(false)}
                    />
                </Stack>
            </BlCard>

            {
                !!coursesCount &&
                <BlCard>
                    <HStack>
                        <FormLabel fontSize={"lg"} fontWeight="bold">
                            Courses ({coursesCount})
                        </FormLabel>
                    </HStack>

                    <TableContent
                        data={courses}
                        columns={[
                            {Header: "Name", accessor: "name"},
                            {
                                Header: "Teachers",
                                accessor: "teachers",
                                Cell: function TeachersCell(data:  ObjectId[]) {
                                    return ( <> {
                                        data.map((teacher: ObjectId) => {
                                            return <Text> { getLookupLabel(teachers, teacher)}</Text>;
                                        })
                                    } </>
                                    )

                                }
                            },
                            {
                                Header: "Course Code",
                                accessor: "course_code",
                                Cell: (data: string) => useChipCell(data, 'red')
                            }
                        ]}
                    />
            </BlCard>
            }


            { !!homeroomsCount &&
                <BlCard>
                    <HStack>
                        <FormLabel fontSize={"lg"} fontWeight="bold">
                            Homerooms ({homeroomsCount})
                        </FormLabel>

                    </HStack>
                    <TableContent
                        data={homerooms}
                        columns={[
                            {Header: "Name", accessor: "name"},
                            {
                                Header: "Teachers",
                                accessor: "teachers",
                                Cell: function TeachersCell(data:  ObjectId[]) {
                                    return ( <>{
                                            data.map((teacher: ObjectId) => {
                                                return <Text> { getLookupLabel(teachers, teacher)}</Text>;
                                            })
                                        } </>
                                    )

                                }
                            },
                        ]}
                    />
                </BlCard>
            }

            <HStack>
                <Spacer/>
                <Button
                    bgColor={"orange.100"}
                    textColor="orange.700"
                    colorScheme='yellow'
                    leftIcon={<FiArchive/>}
                    onClick={archiveSemester}
                    isDisabled={semester?.archived || archiveLoading}
                >
                    Archive
                </Button>
            </HStack>
        </Stack>
    );
});

