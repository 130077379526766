import {HStack, Stack, Switch} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react"
import { AttendanceAPI } from "../../api/AttendanceAPI";
import LocalStorageService from "../../api/LocalStorageService"
import { UserRole } from "../../api/UserApi"
import { useChipCell } from "../../hooks/useChipCell"
import { useTeacherCell } from "../../hooks/useTeacherCell"
import { CourseObj } from "../../models/course"
import { Lookup } from "../../models/lookup"
import { Semester } from "protobuffer-ts/dist/class_service/semester";
import { ObjectId } from "../../utils/ObjectId"
import { TableContent } from "../table/TableContent";
import SearchInput from "../forms/SearchInput";

interface Card {
	teachers: Lookup[]
}

const badgeEnumAttendance: Record<string, string> = {
    done: 'green',
    waiting: 'red',
}

export const AttendanceCourseList = ({ teachers } : Card) => {
	const [is_initialized, set_is_initialized] = useState<boolean>(false); // Tracks if the localStorage fetch has completed
	
	const user = LocalStorageService.getInstance().getUser();
    const canAccessAllCourse = user?.has_role(UserRole.Can_Access_All_Course);

    const [show_all_courses, set_show_all_courses] = useState(false)

	const [courses_name_search, set_courses_name_search] = useState<string>("");
	const [courses_data, set_courses_data] = useState<CourseObj[]>();
	const filtered_courses = useMemo(() => {
		if (!courses_name_search) return courses_data;
		return courses_data?.filter(course =>
			course.name.toLowerCase().includes(courses_name_search.toLowerCase())
		);
	}, [courses_name_search, courses_data]);

    const [loading, setLoading] = useState<boolean>(true);

	const columns = useMemo(() => [
		{
			Header: "Name",
			accessor: "name",
		},
		{
			Header: "Teacher Name",
			accessor: "teacher_ids",
			Cell: (data: ObjectId[]) => useTeacherCell(data, teachers)
		},
		{
			Header: "Semester",
			accessor: "semester",
			Cell: (data: Semester) => useChipCell(data.name, 'blue')
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: (data: string) => useChipCell(data, badgeEnumAttendance[data])
		},
	], [teachers])

	const on_show_all_courses_change = useCallback((v: boolean) => {
        LocalStorageService.getInstance().set_show_all_classes(v)
        if(canAccessAllCourse){
            set_show_all_courses(v)
        }
    }, [canAccessAllCourse])

	useEffect(() => {
		if(canAccessAllCourse) {
			on_show_all_courses_change(LocalStorageService.getInstance().get_show_all_classes())
		}

		set_is_initialized(true);

	}, [canAccessAllCourse, on_show_all_courses_change])


	useEffect(() => {
		// Only run this effect if the initialization has completed
		if (!is_initialized) return;

        const currentDate = new Date();

		setLoading(true);

		AttendanceAPI.get_courses(user!._id, currentDate, show_all_courses)
			.then((res) => {
				let attendance_courses = res?.courses.map((course) => ({
					...course,
					status: res.entry_status[course.id!.id] ? "done" : "waiting",
					link: "/attendance/courses/" + course.id!.id
				}))

				set_courses_data(attendance_courses)
			})
			.finally(() => {
				setLoading(false)
			})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show_all_courses, is_initialized]);

	return (
		<>
			<HStack mb={4}>
				{ canAccessAllCourse &&
					<Switch isChecked={show_all_courses} onChange={(e) => on_show_all_courses_change(e.target.checked)}>Show All Courses</Switch>
				}
			</HStack>

			<Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
				<HStack>
					<SearchInput onChange={set_courses_name_search} />
				</HStack>
			</Stack>

			<TableContent loading={loading} data={filtered_courses} columns={columns} />
		</>
	)
}

