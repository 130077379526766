import {Box,
    Center,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import React from "react";
import {SemesterList} from "../semesters/SemesterList";
import {NotificationList} from "../notifications/NotificationsList";

export const AdminPortal = React.memo(() =>  {

    return (<Box>
            <Center>
                <Heading size="md" mb="6">
                    Admin Portal
                </Heading>
            </Center>
            <Tabs >
                <TabList>
                    <Tab>Semesters</Tab>
                    <Tab>Notifications</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <SemesterList />
                    </TabPanel>
                    <TabPanel>
                        <NotificationList/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>

    );
});
