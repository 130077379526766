import * as React from "react"
import {
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    WrapItem,
    Avatar,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    IconButton,
    Button,
    useColorMode, MenuList, MenuButton, MenuItem, Menu, Center
} from '@chakra-ui/react';
import FocusLock from 'react-focus-lock';
import {
    FiMenu,
    FiChevronUp, FiChevronDown,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { ColorModeSwitcher } from "../../ColorModeSwitcher"
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import LocalStorageService from "../../api/LocalStorageService";


interface LinkItemProps {
  name: string;
  icon: IconType;
  route: string;
}

export default function SimpleSidebar({ link_items }: { link_items: Array<LinkItemProps> }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        link_items={link_items}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} link_items={link_items}/>
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box minH="100vh" ml={{ base: 0, md: 60 }} p="4">
        <Outlet  />
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  link_items: Array<LinkItemProps>,
  onClose: () => void;
}

const SidebarContent = ({ link_items, onClose, ...rest }: SidebarProps) => {
    let navigate = useNavigate();
    let user = LocalStorageService.getInstance().getUser();

    const on_sign_out = () => {
        LocalStorageService.getInstance().clearToken();
        LocalStorageService.getInstance().clear();

        firebase.auth().signOut().then();

        setTimeout(() => {
            navigate('/login');
        }, 0.5);
    };

    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}
        >
            <Flex direction="column" height="full">
                {/* Header */}
                <Flex h="20" alignItems="center" mx="4" justifyContent="space-between">
                    <Text fontSize="xl" fontFamily="Open Sans" fontWeight="bold">
                        Bassem Labs
                    </Text>
                    <Flex alignItems="center">
                        <ColorModeSwitcher />
                        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
                    </Flex>
                </Flex>

                {/* Scrollable Navigation Items */}
                <Box
                    flex="1"
                    overflowY="auto"
                    px="4"
                    py="2"
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: useColorModeValue('gray.300', 'gray.600'),
                            borderRadius: '2px',
                        },
                    }}
                >
                    {link_items.map((link) => (
                        <NavItem key={link.name} icon={link.icon} link={link.route} onClick={onClose}>
                            {link.name}
                        </NavItem>
                    ))}
                </Box>

                {/* Sign Out Section */}
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    p={4}
                    borderTop="1px"
                >
                    <Flex alignItems="center">
                        <Avatar size="sm" src={user?.photo_url} name={user?.full_name} />
                        <Text ml={3} fontSize="sm" fontWeight="medium">
                            {user?.full_name}
                        </Text>
                    </Flex>
                    <Menu placement="top-end">
                        <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<FiChevronDown />}
                            variant="ghost"
                            size="sm"
                        />
                        <MenuList
                            alignItems="center"
                            w="100px"
                            minW="100px"
                            p={2}
                        >
                            <Center w="100%">
                                <Button
                                    onClick={on_sign_out}
                                    colorScheme="red"
                                    size="sm"
                                    w="full"
                                >
                                    Sign Out
                                </Button>
                            </Center>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
        </Box>
    );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  link: string
}
const NavItem = ({ icon, children, link, ...rest }: NavItemProps) => {
  const location = useLocation();

  // Check if the current path matches the link or any of its sub-routes
  const isActive = location.pathname === link || location.pathname.startsWith(link);

  const { colorMode } = useColorMode();
  const isLight = colorMode === "light"


   // Define styles for light and dark modes
  const activeBg = isLight ? "blue.50" : "blue.900";
  const activeColor = isLight ? "blue.600" : "blue.300";
  const hoverBg = isLight ? "blue.50" : "blue.700";
  const hoverColor = isLight ? "blue.600" : "blue.300";

  return (
    <Link to={link} style={{ textDecoration: 'none' }} >
      <Flex
        align="center"
        px="4"
        py="2"
        mb="2"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        fontWeight={isActive ? "semibold" : "normal"}
        bg={isActive ? activeBg : "transparent"}
        color={isActive ? activeColor : "inherit"}
        _hover={{
          bg: hoverBg,
          color: hoverColor,
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: hoverColor,
            }}
            as={icon}
          />
        )}
        <Text fontSize="1xl" fontFamily="Open Sans">
          {children}
        </Text>
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Bassem Labs
      </Text>
    </Flex>
  );
};