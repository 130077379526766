import React from 'react';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';

interface FormDatePickerProps {
    name: string;
    label: string;
    control: Control<any>;
    error?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({
                                                           name,
                                                           label,
                                                           control,
                                                           error,
                                                           isDisabled = false,
                                                           isRequired = false
                                                       }) => {
    return (
        <FormControl isInvalid={!!error}>
            <FormLabel htmlFor={name} className={isRequired ? 'required' : ''}>{label}</FormLabel>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <DatePicker
                        id={name}
                        selected={value}
                        onChange={(date: Date | null) => onChange(date)}
                        disabled={isDisabled}
                        placeholderText="Select Date..."
                        className="react-datapicker__input-text"
                    />
                )}
            />
            <FormErrorMessage>
                {error}
            </FormErrorMessage>
        </FormControl>
    );
};

export default FormDatePicker;