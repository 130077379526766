import { Button, Flex, HStack, Spacer, Stack, Switch } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react"
import { FiPlus } from "react-icons/fi"
import LocalStorageService from "../../api/LocalStorageService"
import { UserRole } from "../../api/UserApi"
import { Lookup } from "../../models/lookup";
import { ObjectId } from "../../utils/ObjectId"
import { CreateCourseDialog } from "./CreateCourseDialog"
import { CourseAPI } from "../../api/CourseAPI"
import { Semester } from "protobuffer-ts/dist/class_service/semester";
import { CourseObj } from "../../models/course"
import { TableContent } from "../table/TableContent"
import { TablePagination } from "../table/TablePagination"
import { useTeacherCell } from "../../hooks/useTeacherCell"
import SearchInput from "../forms/SearchInput";
import { useChipCell } from "../../hooks/useChipCell";

interface Card {
	teachers: Lookup[]
	semesters: Lookup[]
	base_url?: string
	show_add_course?: boolean
}


export const CourseList = ({teachers, semesters, base_url="class", show_add_course=false} : Card) => {
	const [is_initialized, set_is_initialized] = useState<boolean>(false); // Tracks if the localStorage fetch has completed

	const user = LocalStorageService.getInstance().getUser();
    const canAccessAllCourse = user?.has_role(UserRole.Can_Access_All_Course);

    const [show_all_courses, set_show_all_courses] = useState(false)
    const [show_archived, set_show_archived] = useState(false)

	const [isOpen_course, setIsOpen_course] = useState(false)
    const onClose_course = () => setIsOpen_course(false)

	const PER_PAGE = 15

    const [courses_data, set_courses_data] = useState<CourseObj[]>();
    const [courses_page, set_courses_page] = useState<number>(1);
    const [courses_max_page, set_courses_max_page] = useState<number>();
    const [total_courses, set_total_courses] = useState<number>();
    const [courses_name_search, set_courses_name_search] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);

	const columns = useMemo(() => [
		{
			Header: "Name",
			accessor: "name",
		},
		{
			Header: "Teacher Name",
			accessor: "teachers",
			Cell: (data: ObjectId[]) => useTeacherCell(data, teachers)
		},
		{
			Header: "Semester",
			accessor: "semester",
			Cell: (data: Semester) => useChipCell(data.name, 'blue')
		},
		{
			Header: "Course Code",
			accessor: "course_code",
			Cell: (data: string) => useChipCell(data, 'red')
		}
	], [teachers])

	const on_show_all_courses_change = useCallback((v: boolean) => {
        LocalStorageService.getInstance().set_show_all_classes(v)
        if(canAccessAllCourse){
            set_show_all_courses(v)
        }
    }, [canAccessAllCourse])


	useEffect(() => {
		if(canAccessAllCourse)
            on_show_all_courses_change(LocalStorageService.getInstance().get_show_all_classes())

		set_is_initialized(true);

    }, [canAccessAllCourse, on_show_all_courses_change])

	// Watch for search term change
	useEffect(() => {
		set_courses_page(1); // Reset to first page on search term change
	}, [courses_name_search]);


	useEffect(() => {
		// Only run this effect if the initialization has completed
		if (!is_initialized) return;

		const teacherParam = !show_all_courses ? user!._id : undefined

		setLoading(true);

		CourseAPI.course_list(PER_PAGE, courses_page, courses_name_search, show_archived, teacherParam, true)
			.then((res) => {
				let courses = res.courses.map((course) => ({
					...course,
					link: '/' + base_url + '/courses/' + course._id.$oid
				}))

				set_courses_data(courses)
				set_total_courses(res.courses_count)
				set_courses_max_page(Math.ceil((res.courses_count || 0 ) / 15))
		}).finally(() => {
			setLoading(false);
		})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courses_name_search, courses_page, show_all_courses, show_archived, is_initialized]);

	return (
		<>
		<HStack rowGap="4" mb={4}>
			<Flex direction={{ base: 'column', md: 'row' }} gap="2">
				{ canAccessAllCourse &&
					<Switch isChecked={show_all_courses} onChange={(e) => on_show_all_courses_change(e.target.checked)}>Show All Courses</Switch>
				}

				<Switch isChecked={show_archived} onChange={(e) => set_show_archived(e.target.checked)}>Show Archived</Switch>
			</Flex>

			<Spacer />

			{ user?.has_role(UserRole.Can_Create_Course) && show_add_course &&
				<>
				<Button
					colorScheme='green'
					leftIcon={<FiPlus/>}
					onClick={() => setIsOpen_course(true)}
				>
					Add Course
				</Button>

				<CreateCourseDialog
					isOpen={isOpen_course} onClose={onClose_course} semesters={semesters} teachers={teachers}
				/>
				</>
			}
		</HStack>

		<Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
			<HStack>
				<SearchInput onChange={set_courses_name_search} />
			</HStack>
		</Stack>

		<TableContent loading={loading} data={courses_data} columns={columns} />

		<TablePagination text={"Showing " + PER_PAGE*courses_page + " of " + total_courses} set_page={set_courses_page} page={courses_page} max_page={courses_max_page!}/>
		</>
	)
}
