import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Textarea,
    useToast,
    Badge,
    Spacer,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AttendanceAPI } from '../../api/AttendanceAPI';
import { Student } from '../../models/student';
import { Course } from "protobuffer-ts/dist/class_service/course";
import { Attendance } from '../../models/attendance';
import { Homeroom } from 'protobuffer-ts/dist/class_service/homeroom';

interface ExcuseStudentProps {
    isOpen: boolean;
    onClose: () => void;
    course?: Course;
    homeroom?: Homeroom
    selected_date?: Date;
    student: Student;
}

const ExcuseStudent: React.FC<ExcuseStudentProps> = ({ isOpen, onClose, course, homeroom, student, selected_date }) => {
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(selected_date); // Initialize with passed selected_date
    const [reason, setReason] = useState('');
    const [attendanceEntry, setAttendanceEntry] = useState<Attendance | null>(null); // State to store the attendance entry
    const toast = useToast();

    const getAttendanceEntry = async (date: Date | null) => {
        if (!date) return;

        const ClassSingleStudentAPI = homeroom
            ? AttendanceAPI.get_homeroom_attendance_entry_for_single_student
            : AttendanceAPI.get_course_attendance_entry_for_single_student;

        const res = await ClassSingleStudentAPI(
            student._id.$oid,
            (course ? course.id!.id : homeroom!.id!.id),
            date
        );
        if (res && res.attendance_entry) {
            setAttendanceEntry(res.attendance_entry);
            setReason(res.attendance_entry.reason);
        } else {
            setAttendanceEntry(null);
            toast({
                title: 'No Entry Found',
                description: `No attendance entry was found for the selected date.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDateChange = (date: Date) => {
        setSelectedDate(date); // Use date directly
        getAttendanceEntry(date).then(); // Fetch attendance entry for the date
    };

    const handleSubmit = async () => {
        if (!selectedDate || !reason || !attendanceEntry) {
            toast({
                title: 'Error',
                description: 'Please select a date and provide a reason.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            await AttendanceAPI.update_excuse_student(attendanceEntry._id.$oid, reason);
            toast({
                title: 'Success',
                description: 'Student excused successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose(); // Close the modal if the update is successful
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Error excusing student: ' + error,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Fetch attendance entry on mount if a selected_date is passed
    useEffect(() => {
        if (selected_date) {
            setSelectedDate(selected_date); // Set selected_date without any UTC conversion
            getAttendanceEntry(selected_date).then(); // Fetch entry for passed date
        }
    }, [selected_date]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Excuse Student</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl id="student_name">
                        <FormLabel>Student Name</FormLabel>
                        <Badge colorScheme={'blue'} size={'lg'}>{student.profile.first_name + " " + student.profile.last_name}</Badge>
                    </FormControl>
                    <Spacer height={"10px"}/>
                    <FormControl id="course_name">
                        <FormLabel>{course ? "Course:" : "Homeroom:"}</FormLabel>
                        <Badge colorScheme={'blue'} size={'lg'}>
                            {course ? course.name : homeroom?.name}
                        </Badge>
                    </FormControl>
                    <Spacer height={"10px"}/>
                    <FormControl id="date" isRequired>
                        <FormLabel>Select Date</FormLabel>
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd-MM-yyyy" // Ensure correct format in DatePicker
                        />
                    </FormControl>
                    <FormControl id="reason" mt={4} isRequired>
                        <FormLabel>Reason</FormLabel>
                        <Textarea
                            placeholder="Enter the reason for excusing the student"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleSubmit}>
                        Excuse Student
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ExcuseStudent;