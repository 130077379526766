import { Delta } from "quill/core";
import { TemplatePlaceholders } from "../models/comment_template";
import { Student } from "../models/student";

export const formatContentWithHighlight = (content : string) => {
	const delta = new Delta()

	// Split the content by {{placeholder}}
	const parts = content.split(/({{.*?}})/);

	// Apply placeholder styles
	parts.forEach((part) => {
		delta.insert(part, part.match(/{{.*?}}/) ? { background: "#e2e8f0" } : {});
	});

	return delta;
};


export const retrieveCommentPlaceholders = (comment: string, student : Student) => {
	const profile = student.profile
	const { first_name, last_name, gender } = profile;
  
	const replacements = {
		[TemplatePlaceholders.Name]: `${first_name} ${last_name}`,
		[TemplatePlaceholders.HeShe]: gender === 'M' ? 'he' : 'she',
		[TemplatePlaceholders.HisHer]: gender === 'M' ? 'his' : 'her',
		[TemplatePlaceholders.HimHers]: gender === 'M' ? 'him' : 'hers',
	};

	// Replace placeholders in the template with actual values
	let filledComment = comment
	.replace(new RegExp(`{{${TemplatePlaceholders.Name}}}`, 'g'), replacements[TemplatePlaceholders.Name])
	.replace(new RegExp(`{{${TemplatePlaceholders.HeShe}}}`, 'g'), replacements[TemplatePlaceholders.HeShe])
	.replace(new RegExp(`{{${TemplatePlaceholders.HisHer}}}`, 'g'), replacements[TemplatePlaceholders.HisHer])
	.replace(new RegExp(`{{${TemplatePlaceholders.HimHers}}}`, 'g'), replacements[TemplatePlaceholders.HimHers])
	  
	return filledComment;
  };