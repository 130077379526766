
import { formatDistance } from "date-fns";
import { SemesterReportLayout, ReportDates, Semester, ReportType } from "protobuffer-ts/dist/class_service/semester";
import { ObjectId, to_oid } from "../utils/ObjectId";

export class SemesterUpdate {
    _id: ObjectId;
    name: string;
    start_date: Date;
    end_date: Date;
    report_layout: SemesterReportLayout;

    constructor(data: Semester) {
        this._id = to_oid(data.id!.id)
        this.name = data.name;
        this.start_date = data.startDate!;
        this.end_date = data.endDate!;
        this.report_layout = SemesterReportLayout.fromJSON(data.reportLayout);
    }
}


export interface CreateSemester {
    name: string;
    start_date: Date;
    end_date: Date;
}

export const gerReportDate = (reportDates: ReportDates[] = [], reportType: ReportType | undefined, dateType: 'dueDate' | 'distributionDate'): string | undefined => {
    const date = reportDates.find(date => date.reportType === reportType)?.[dateType]

    if (date) return formatDistance(new Date(date), new Date(), { addSuffix: true})
};

export const learningSkillsOptions = [
    "Responsibility",
    "Organization",
    "Independent Work",
    "Initiative",
    "Collaboration",
    "Self-Regulation",
]

export const learningSkillValues = ["Excellent", "Good", "Satisfactory", "Needs Improvement"]