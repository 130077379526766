import React, { useState, useEffect } from 'react';
import { Box, Text, Select, HStack, Spacer } from '@chakra-ui/react';
import { CourseAPI } from '../../api/CourseAPI';
import { HomeroomAPI } from '../../api/HomeroomAPI';
import { StudentAPI } from '../../api/StudentAPI';
import SubmissionStats from './SubmissionStats';
import { SubmissionsWithGClassWork } from '../../models/gclass';
import { mergeSubmissionsWithCourseWork } from '../../utils/GClass';
import GClassViewerPlaceholder from './GClassViewerPlaceholder';
import { Homeroom } from 'protobuffer-ts/dist/class_service/homeroom';
import { Course } from "protobuffer-ts/dist/class_service/course";
import { getSubmissionStatus } from '../../hooks/useSubmissionsCount';
import GClassItemCard from './GClassItemCard';
import { getLookupLabel, Lookup } from '../../models/lookup';
import { StudentStatus } from '../../models/student';
import { proto_utils } from '../../utils/proto_utils';
import { GClassCourseWork } from 'protobuffer-ts/dist/class_service/gclass';

interface Props {
    item: Course | Homeroom;
    type: 'homeroom' | 'course';
}

type GroupedSubmissions = {
    [title: string]: SubmissionsWithGClassWork[];
};

const GClassItemViewer: React.FC<Props> = ({ type, item }) => {
    const [submissions, setSubmissions] = useState<SubmissionsWithGClassWork[]>([]);
    const [students, setStudents] = useState<Lookup[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // Filters
    const [selectedStatus, setSelectedStatus] = useState<string>('');

    useEffect(() => {
		StudentAPI.students_list(undefined, undefined, null, StudentStatus.Enrolled).then(res => {
			const data = res!.students.map(e => ({
				value: e._id,
				label: `${e.profile.first_name} ${e.profile.last_name}`
			}))
			setStudents(data)
		})
	}, [])



    useEffect(() => {
        let isMounted = true; // Track if the component is mounted

        // Return out of this if the class has no google classroom
        if (!item.gclassId) {
            return;
        }
    
        setSubmissions([]);
        setLoading(true);
    
        const fetchItemData = async () => {
            try {
                const courseWork: GClassCourseWork[] = await (type === 'homeroom'
                    ? HomeroomAPI.get_gclass_work(proto_utils.from_proto_object_id(item!.id!))
                    : CourseAPI.get_gclass_work(proto_utils.from_proto_object_id(item!.id!)));
        
                // Fetch all submissions concurrently
                const submissionsPromises = item.studentIds.map(async studentId => {
                    const submissions = type === 'homeroom'
                        ? await StudentAPI.get_gclass_homeroom_submissions(proto_utils.from_proto_object_id(studentId), proto_utils.from_proto_object_id(item!.id!))
                        : await StudentAPI.get_gclass_course_submissions(proto_utils.from_proto_object_id(studentId), proto_utils.from_proto_object_id(item!.id!));

                    // Assign student_name to each submission
                    const studentName = getLookupLabel(students, proto_utils.from_proto_object_id(studentId));
                    return submissions.map(submission => ({
                        ...submission,
                        student_name: studentName,
                    }));
                });

                const allSubmissionsArrays = await Promise.all(submissionsPromises);
                
                // Flatten the array of arrays
                const allSubmissions = allSubmissionsArrays.flat();
        
                const mergedSubmissions = mergeSubmissionsWithCourseWork(allSubmissions, courseWork);
        
                if (!isMounted) return;
        
                setSubmissions(mergedSubmissions);
                setLoading(false);
            } catch (error) {
                if (!isMounted) return;
        
                console.error("Error fetching data", error);
                setLoading(false);
            }
        };
    
        fetchItemData();
    
        return () => {
            isMounted = false; // Cleanup function to mark the component as unmounted
        };
    }, [type, item, students]);

    const filteredSubmissions = submissions.filter(sub => {
        if (!selectedStatus) return true;
        return getSubmissionStatus(sub) === selectedStatus;
    });

    // Group submissions by title
    const groupedSubmissions: GroupedSubmissions = filteredSubmissions.reduce((groups, sub) => {
        const { title } = sub;

        if (!groups[title]) groups[title] = [];

        groups[title].push(sub);

        return groups;
    }, {} as GroupedSubmissions);
    

    return (
        <Box mt={4}>
            {loading ? (
                <GClassViewerPlaceholder />
            ) : (
                submissions && (
                    <>
                        <Text fontSize="lg" fontWeight="bold" mb={4} textTransform="capitalize">
                            Statistics
                        </Text>

                        <SubmissionStats submissions={filteredSubmissions} />

                        <HStack justify="space-between" mb={4}>
                            <Text fontSize="lg" fontWeight="bold" textTransform="capitalize">
                                Assignments
                            </Text>

                            <Spacer />

                            <Select maxW={300} placeholder="All Submissions" onChange={(e) => setSelectedStatus(e.target.value)} mb={4}>
                                <option value="notSubmitted">Not Submitted</option>
                                <option value="notSubmittedLate">Not Submitted (Late)</option>
                                <option value="submittedOnTime">Submitted On Time</option>
                                <option value="submittedLate">Submitted Late</option>
                            </Select>

                        </HStack>

                        { Object.entries(groupedSubmissions).map(([title, groupSubmissions]) => (
                            <GClassItemCard
                                key={title}
                                data={{
                                    _id: proto_utils.from_proto_object_id(item!.id!),
                                    name: title,
                                    submissions: groupSubmissions
                                }}
                            />
                        ))}                    
                    </>
                )
            )}
        </Box>
    );
};

export default GClassItemViewer;
