import React, { useEffect, useState, useCallback } from "react";
import {
    Alert,
    AlertDescription,
    Box,
    Button,
    Center,
    Divider,
    Heading,
    HStack,
    IconButton,
    Spacer,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { EvaluationAPI } from "../../../api/EvaluationAPI";
import { StudentAPI } from "../../../api/StudentAPI";
import { EvaluationEntryAPI } from "../../../api/EvaluationEntryAPI";
import { to_oid, ObjectId } from "../../../utils/ObjectId";
import { CourseAPI } from "../../../api/CourseAPI";
import { EvaluationEntryTable } from "./EvaluationEntryTable";
import { StudentsFinalMarkTable } from "../StudentView/StudentsFinalMarkTable";
import { MarkBookCategories } from "../MarkCategory/MarkBookCategories";
import { StudentSelector } from "../StudentView/StudentSelector";
import { CreateEvaluation } from "./CreateEvaluationDialog";
import { Evaluation } from "../../../models/evaluation";
import { EvaluationEntry } from "../../../models/evaluation_entry";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { Student } from "../../../models/student";
import {EvaluationsTable} from "./EvaluationsTable";

interface StudentMarkData {
    student: Student;
    final_mark?: number;
    maximum_potential_mark?: number;
}

export const MarkbookCourse = () => {
    const { course_id } = useParams<{ course_id: string }>();
    const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
    const [selectedEvaluation, setSelectedEvaluation] = useState<Evaluation | null>(null);
    const [evaluationEntries, setEvaluationEntries] = useState<
        { student: Student; evaluation_entry: EvaluationEntry }[]
    >([]);
    const [course, setCourse] = useState<Course | null>(null);
    const [stats, setStats] = useState<{ mean: string; median: string; totalWeight: string } | null>(null);
    const [studentsFinalMark, setStudentsFinalMark] = useState<StudentMarkData[]>([]);
    const [isCreateEvalOpen, setIsCreateEvalOpen] = useState(false);

    const toast = useToast();
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const fetchCourse = useCallback(async () => {
        try {
            const courseData = await CourseAPI.get_course(to_oid(course_id!));
            setCourse(courseData);
        } catch (error) {
            console.error("Failed to fetch course:", error);
        }
    }, [course_id]);

    const fetchEvaluations = useCallback(async () => {
        try {
            const evals = await EvaluationAPI.get_evaluation_list_from_course(to_oid(course_id!));
            setEvaluations(evals || []);
        } catch (error) {
            console.error("Failed to fetch evaluations:", error);
        }
    }, [course_id]);

    const fetchStudentsFinalMark = useCallback(async () => {
        try {
            const studentList = await StudentAPI.students_list(
                undefined,
                undefined,
                null,
                undefined,
                to_oid(course_id!),
                undefined
            );
            const finalMarks = await EvaluationAPI.get_students_final_mark(to_oid(course_id!));
            const students = studentList!.students;
            const marksData = finalMarks!.map((entry) => ({
                student: students.find((s) => s._id.$oid === entry.student.$oid)!,
                final_mark: entry.final_mark,
                maximum_potential_mark: entry.maximum_potential_mark,
            }));
            setStudentsFinalMark(marksData);
        } catch (error) {
            console.error("Failed to fetch students' final marks:", error);
        }
    }, [course_id]);

    const fetchEvaluationEntries = useCallback(
        async (evaluation_id: ObjectId) => {
            try {
                const studentList = await StudentAPI.students_list(
                    undefined,
                    undefined,
                    null,
                    undefined,
                    to_oid(course_id!),
                    undefined
                );
                const evalEntries = await EvaluationEntryAPI.get_evaluation_entries_list_from_evaluation(
                    evaluation_id
                );
                const students = studentList!.students;
                const entries = evalEntries!.map((e) => ({
                    student: students.find((s) => s._id.$oid === e.student.$oid)!,
                    evaluation_entry: e,
                }));
                setEvaluationEntries(entries);
            } catch (error) {
                console.error("Failed to fetch evaluation entries:", error);
            }
        },
        [course_id]
    );

    const fetchEvaluationStats = useCallback(async (evaluation_id: ObjectId) => {
        try {
            const statsData = await EvaluationAPI.get_evaluation_statistics(evaluation_id);
            setStats({
                mean: `${statsData.mean.toFixed(2)}%`,
                median: `${statsData.median.toFixed(2)}%`,
                totalWeight: `${statsData.total_weight}%`,
            });
        } catch (error) {
            console.error("Failed to fetch evaluation statistics:", error);
        }
    }, []);

    useEffect(() => {
        fetchCourse().then();
        fetchEvaluations().then();
        fetchStudentsFinalMark().then();
    }, [fetchCourse, fetchEvaluations, fetchStudentsFinalMark]);

    useEffect(() => {
        if (selectedEvaluation) {
            fetchEvaluationEntries(selectedEvaluation._id).then();
            fetchEvaluationStats(selectedEvaluation._id).then();
        }
    }, [selectedEvaluation, fetchEvaluationEntries, fetchEvaluationStats]);

    const handleEvaluationSelect = (evaluation: Evaluation) => {
        setSelectedEvaluation(evaluation);
    };

    const handleCreateEvaluationClose = () => {
        setIsCreateEvalOpen(false);
        fetchEvaluations().then();
    };

    return (
        <Box>
            <Center>
                <Heading size="md" mb="6">
                    Markbook
                </Heading>
            </Center>
            <Center>
                <Text>Course: {course?.name}</Text>
            </Center>

            <Tabs variant="enclosed" onChange={()=>{
                // fetchStudentsFinalMark().then();
            }}>
                <TabList>
                    <Tab>Mark Books View</Tab>
                    <Tab>Final Marks View</Tab>
                    <Tab>Student View</Tab>
                    <Tab>Mark Categories</Tab>
                </TabList>

                <TabPanels>
                    {/* Mark Books View */}
                    <TabPanel>
                        <Stack direction="row" spacing={4}>
                            <Box flex="7">
                                <Stack spacing={4}>
                                    <EvaluationsTable
                                        evaluations={evaluations}
                                        onEvaluationSelect={handleEvaluationSelect}
                                        refreshEvaluations={fetchEvaluations}
                                    />
                                    <Button
                                        leftIcon={<FiPlus />}
                                        colorScheme="green"
                                        onClick={() => setIsCreateEvalOpen(true)}
                                        alignSelf="flex-start"
                                    >
                                        Add Evaluation
                                    </Button>
                                    <CreateEvaluation
                                        class_id={to_oid(course_id!)}
                                        isOpen={isCreateEvalOpen}
                                        onClose={handleCreateEvaluationClose}
                                        cancelRef={cancelRef}
                                    />
                                </Stack>
                            </Box>
                            {selectedEvaluation && (
                                <Box flex="3">
                                    <Alert status="info" borderRadius="md" mb={4}>
                                        <HStack spacing={4}>
                                            <AlertDescription>
                                                <Text>
                                                    <strong>Markbook Total Weight:</strong> {stats?.totalWeight}
                                                </Text>
                                            </AlertDescription>
                                            <Divider orientation="vertical" />
                                            <AlertDescription>
                                                <Text>
                                                    <strong>Evaluation Average Mark:</strong> {stats?.mean}
                                                </Text>
                                            </AlertDescription>
                                            <Divider orientation="vertical" />
                                            <AlertDescription>
                                                <Text>
                                                    <strong>Evaluation Median Mark:</strong> {stats?.median}
                                                </Text>
                                            </AlertDescription>
                                        </HStack>
                                    </Alert>
                                    <EvaluationEntryTable
                                        evaluation={selectedEvaluation}
                                        entries={evaluationEntries}
                                        refreshEntries={() => fetchEvaluationEntries(selectedEvaluation._id)}
                                    />
                                </Box>
                            )}
                        </Stack>
                    </TabPanel>

                    {/* Final Marks View */}
                    <TabPanel>
                        <StudentsFinalMarkTable entry_data={studentsFinalMark} />
                    </TabPanel>

                    {/* Student View */}
                    <TabPanel>
                        <StudentSelector students_marks={studentsFinalMark} />
                    </TabPanel>

                    {/* Mark Categories */}
                    <TabPanel>
                        <MarkBookCategories class_id={to_oid(course_id!)} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};