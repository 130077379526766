import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Badge,
    FormLabel,
    Box,
    Text,
    Stack,
    Spacer,
    HStack,
    Flex,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
	ButtonGroup,
	Button,
	useToast,
} from '@chakra-ui/react';
import {
	ReportEntry,
    ReportTypeColor,
    reportTypeOptions,
    ReviewStatus,
    ReviewStatusColor,
    reviewStatusOptions,
} from "../../models/report_entry";
import { ObjectId } from "../../utils/ObjectId";
import { getLookupLabel, Lookup } from '../../models/lookup'
import { useChipCell } from "../../hooks/useChipCell"
import { TableContent } from "../table/TableContent";
import { ReportAPI } from "../../api/ReportAPI";
import { FlexWrap } from "../layout/FlexWrap";
import { Select, chakraComponents } from "chakra-react-select";
import { ReportType, reportTypeFromJSON, reportTypeToJSON, Semester } from "protobuffer-ts/dist/class_service/semester";
import { StudentAPI } from "../../api/StudentAPI";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { convertCaseToNormal } from "../../utils/helpers";
import { BlCard } from "../layout/Card";
import { SemesterAPI } from "../../api/SemesterAPI";
import { StudentReportEntry } from "./StudentReportEntry";
import { CommentTemplateAPI } from "../../api/CommentTemplateAPI";
import { CommentTemplate } from "../../models/comment_template";
import { BsGrid, BsTable } from "react-icons/bs";

interface Props {
	teacher_id?: ObjectId
  }

export const ReportsQueue = React.memo(({ teacher_id }: Props) => {
    const [view, set_view] = useState<'table' | 'grid'>('table');
	const toast = useToast()


	const [report_entries, set_report_entries] = useState< ReportEntry[]>([]);
    const [students, set_students] = useState<Lookup[]>([]);
    const [semesters, set_semesters] = useState<Lookup[]>([]);
    const [comment_templates, set_comment_templates] = useState<CommentTemplate[]>([]);

	const [loading, set_loading] = useState<boolean>(true);
	const [selected_report, set_selected_report] = useState< ReportEntry>();
	const [selected_semester_id, set_selected_semester_id] = useState<ObjectId>();
	const [selected_semester, set_selected_semester] = useState<Semester>();
	const [selected_status, set_selected_status] = useState<ReviewStatus| undefined>(ReviewStatus.Filled);
	const [selected_report_type, set_selected_report_type] = useState<ReportType>();

    const cancelRef = useRef<HTMLButtonElement>(null);

	const fetchAllData = async () => {
		set_loading(true)

		SemesterAPI.semester_list().then((res) => {
			let data = res!.semesters.map((e) => ({ value: e._id, label: e.name }))

			set_semesters(data)
		})

		ReportAPI.get_report_entries_queue(teacher_id, selected_semester_id)
			.then((res : ReportEntry[]) => {
				// map over report entries and add a link
				const entries = res.map(report => ({
					...report,
					run_func: () => onRowClick(report)
				}))

				set_report_entries(entries)

				// loop over entries students and get their names 
				const studentsIds = res.map(report => report.student)

				if (!studentsIds.length) set_students([])
				
				else
					StudentAPI.students_by_ids(studentsIds).then(res => {
						const students = res.map((student) => ({
							value: student._id,
							label: `${student.profile.first_name} ${student.profile.last_name}`
						}))

						set_students(students)
					})

			}).catch((err) => {
				toast({
					title: 'Failed to Retrive Entries.',
					description: err.response.data,
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			}).finally(() => {
				set_loading(false)
			})
		
		updateTemplates()
	}

    useEffect(() => {
		if (selected_semester_id) {
			SemesterAPI.get_semester(selected_semester_id)
				.then(semester => set_selected_semester(semester))
				.catch((err) => {
					toast({
						title: 'Failed to Retrive Entries.',
						description: err.response.data,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				})
		}
		fetchAllData()
    }, [selected_semester_id])

	const filtered_entries = useMemo(() => report_entries.filter((entry) => {
		const matches_review_status = selected_status ? entry.review_status === selected_status : true;
		const matches_report_type = selected_report_type ? entry.report_type === selected_report_type : true;

		return matches_review_status && matches_report_type;
	}), 
	[report_entries, selected_status, selected_report_type]);

	const review_status_count = useMemo(() => ({
		[ReviewStatus.NotFilled]: report_entries.filter(entry => entry.review_status === ReviewStatus.NotFilled).length,
		[ReviewStatus.Filled]: report_entries.filter(entry => entry.review_status === ReviewStatus.Filled).length,
		[ReviewStatus.ChangesRequested]: report_entries.filter(entry => entry.review_status === ReviewStatus.ChangesRequested).length,
		[ReviewStatus.Completed]: report_entries.filter(entry => entry.review_status === ReviewStatus.Completed).length,
		[ReviewStatus.Approved]: report_entries.filter(entry => entry.review_status === ReviewStatus.Approved).length,
		[ReviewStatus.Published]: report_entries.filter(entry => entry.review_status === ReviewStatus.Published).length,
	}), [report_entries])

	const columns = [
		{
			Header: 'Student',
			accessor: 'student',
			Cell: (data: ObjectId) => (
				<Text size="sm" me="1" fontWeight="semibold" key={data.$oid}>
					{getLookupLabel(students, data)}
				</Text>
			)
		},

		{
			Header: 'Class Name',
			accessor: 'class_name',
			Cell: (data : string) => (
				<Text size="sm" me="1" fontWeight="semibold">
					{data}
				</Text>
			)
		},

		{
			Header: 'Report Type',
			accessor: 'report_type',
			Cell: (data: ReportType) => useChipCell(reportTypeToJSON(data), ReportTypeColor[reportTypeFromJSON(data)])
		},

		{
			Header: 'Review Status',
			accessor: 'review_status',
			Cell: (data: ReviewStatus) => useChipCell(convertCaseToNormal(data), ReviewStatusColor[data])
		},

		{
			Header: 'Requested Changes',
			accessor: 'requested_changes',
			Cell: (data: string) => data || <Badge fontSize='xs' colorScheme="gray"> No Request</Badge>
		},
	]

	const onRowClick = (report : ReportEntry) => {
		set_selected_report(report)
	}

	const updateTemplates = async () => {
        try {
            const res = await CommentTemplateAPI.list()
    
            set_comment_templates(res)
        } catch (error) {}
    }

	const onItemChanged = () => {
		// remove selected report
		set_selected_report(undefined)

		// refresh data
		fetchAllData()
	}

    return (
		<>

		<BlCard mb="4">
			<FlexWrap>
				<Stack spacing="0">
					<HStack spacing="0">
						<FormLabel fontSize="lg" fontWeight="bold" mb="0">
							List Of Reports
						</FormLabel>

						<Badge colorScheme="purple" rounded="md">
							{filtered_entries.length ? `${filtered_entries.length} Total Records` : 'No Records'}
						</Badge>
					</HStack>

					<FormLabel textColor="gray.500">
						You can filter by Report Type, Review Status 
					</FormLabel>
				</Stack>

				<Spacer />

				<FlexWrap>
					<Box minW={300}>
						<FormLabel>Semester</FormLabel>

						<Select
							isClearable
							options={semesters}
							placeholder="Select Semester"
							onChange={(e: any) => {set_selected_semester_id(e?.value as ObjectId)}}
						/>
					</Box>

					<Box minW={300}>
						<FormLabel> Report Type </FormLabel>

						<Select
							isClearable
							value={reportTypeOptions(selected_semester).find(option => option.value === selected_report_type)}
							options={reportTypeOptions(selected_semester)}
							onChange={e => set_selected_report_type(e?.value)}
							placeholder="Select Report Type"
						/>
					</Box>

					<Box minW={300}>
						<FormLabel> Review Status </FormLabel>

						<Select	
							isClearable
							value={reviewStatusOptions.find(option => option.value === selected_status)}
							options={reviewStatusOptions}
							onChange={e => set_selected_status(e?.value)}
							placeholder="Select Review Status"
							components={{Option: ({ children, ...props }) => (
								<chakraComponents.Option {...props}>
									<Flex justify="space-between" align="center" width="100%">
										<span>{convertCaseToNormal(children as string)}</span>

										<Badge 
											size="sm" 
											colorScheme={ReviewStatusColor[children as ReviewStatus]} 
											rounded="md"
										>
											{review_status_count[children as ReviewStatus] }
										</Badge>
									</Flex>
							  </chakraComponents.Option>							
							)}}
						/>
					</Box>

					<Box>
						<FormLabel> View </FormLabel>

						<ButtonGroup size='sm' isAttached variant="outline">
							<Button 
								leftIcon={<BsGrid />} 
								isActive={view === 'grid'}
								_active={{bgColor: 'blue.50', textColor: 'blue.500'}} 
								onClick={() => set_view('grid')}
							>
								Grid
							</Button>

							<Button 
								leftIcon={<BsTable />} 
								isActive={view === 'table'}
								_active={{bgColor: 'blue.50', textColor: 'blue.500'}} 
								onClick={() => set_view('table')}
							>
								Table
							</Button>
						</ButtonGroup>
					</Box>
				</FlexWrap>
			</FlexWrap>
		</BlCard>

		{ view === 'table' ? (
			<>
			<TableContent columns={columns} data={filtered_entries} loading={loading} />

			<AlertDialog
				isOpen={!!selected_report}
				leastDestructiveRef={cancelRef}
				onClose={() => set_selected_report(undefined)}
			>
			<AlertDialogOverlay>
				<AlertDialogContent minW={{ md: 800, lg: 900}}>
					{ selected_report && 
						
					<StudentReportEntry
						report_entry={selected_report}
						comment_templates={comment_templates}
						update_templates={updateTemplates}
						on_action_ended={onItemChanged}
					/>
					}
				</AlertDialogContent>
			</AlertDialogOverlay>
			</AlertDialog>
			</>
		) : (
			<Stack>
				
			{ filtered_entries.map((entry, index) => 
				// <Text>{entry._id.$oid}</Text>
				<StudentReportEntry
					key={entry._id.$oid}
					report_entry={entry}
					comment_templates={comment_templates}
					update_templates={updateTemplates}
					on_action_ended={onItemChanged}
				/>
			)}
		</Stack>
		)}
		</>
    );
});
