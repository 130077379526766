import { ObjectId } from "../utils/ObjectId";
import { GClassStudentSubmission, States } from "protobuffer-ts/dist/class_service/gclass"

export interface SubmissionsWithGClassWork extends GClassStudentSubmission {
    title: string;
    student_name?: string;
    due_date?: Date;
}

export interface CourseOrHomeroomData {
    _id: ObjectId;
    name: string;
    submissions: SubmissionsWithGClassWork[];
}


export const StateText = (state : States) => {
    switch (state) {
        case States.STATES_CREATED:
            return "New";
        case States.STATES_NEW:
            return "New";
        case States.STATES_RECLAIMED_BY_STUDENT:
            return "Reclaimed By Student";
        case States.STATES_RETURNED:
            return "Returned";
        case States.STATES_SUBMISSION_STATE_UNSPECIFIED:
            return "Submission State Unspecified";
        case States.STATES_TURNED_IN:
            return "Turned In";
        case States.STATES_NOOP:
            return "";
        case States.STATES_FALLTHROUGH_STRING:
            return "Fallthrough String";
        default:
            return state;
    }
}