import {
    Badge,
    Box,
    Button,
    Center,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    Icon,
    Spinner,
    Stack,
    Text,
    useToast,
    Grid,
    GridItem,
    VStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationAPI } from "../../api/NotificationAPI";
import { to_oid } from "../../utils/ObjectId";
import { Notification } from "../../models/notification";
import { FiUser, FiCalendar, FiType } from "react-icons/fi";

export const NotificationDetail = () => {
    const { notification_id } = useParams<{ notification_id: string }>();
    const navigate = useNavigate(); // For navigation (e.g., back button)
    const toast = useToast(); // For displaying error messages

    const [notification, setNotification] = useState<Notification | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchNotification = async () => {
            setLoading(true);
            try {
                const notification = await NotificationAPI.get_notification(to_oid(notification_id!));
                setNotification(notification);
            } catch (err: any) {
                console.error("Error fetching notification:", err);
                setError(err.message || "An unexpected error occurred.");
                toast({
                    title: "Error",
                    description: err.message || "Failed to load the notification.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchNotification();
    }, [notification_id, toast]);

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    if (loading) {
        return (
            <Center minH="100vh">
                <Spinner size="xl" color="teal.500" />
            </Center>
        );
    }

    if (error) {
        return (
            <Center minH="100vh">
                <Box textAlign="center" p={6}>
                    <Heading size="lg" mb={4} color="red.500">
                        {error}
                    </Heading>
                    <Button
                        onClick={handleBack}
                        colorScheme="teal"
                        leftIcon={<ArrowBackIcon />}
                    >
                        Go Back
                    </Button>
                </Box>
            </Center>
        );
    }

    return (
        <Container maxW="container.lg" py={10}>
            <Button
                mb={6}
                onClick={handleBack}
                colorScheme="teal"
                variant="outline"
                leftIcon={<ArrowBackIcon />}
            >
                Back
            </Button>

            <Box
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={8}
                boxShadow="lg"
                bg="white"
            >
                <VStack align="start" spacing={6}>
                    <Heading as="h2" size="md">
                        {notification?.title}
                    </Heading>

                    <Grid templateColumns={{ base: "1fr", md: "200px 1fr" }} gap={4} width="100%">
                        <Flex align="center">
                            <Icon as={FiUser} mr={2} color="teal.500" />
                            <Text fontWeight="bold">Created By:</Text>
                        </Flex>
                        <Text>{notification?.created_by}</Text>

                        <Flex align="center">
                            <Icon as={FiCalendar} mr={2} color="teal.500" />
                            <Text fontWeight="bold">Created On:</Text>
                        </Flex>
                        <Text>{notification?.created}</Text>

                        <Flex align="center">
                            <Icon as={FiCalendar} mr={2} color="teal.500" />
                            <Text fontWeight="bold">Send Date:</Text>
                        </Flex>
                        <Text>{notification?.date_to_send.to_utc_string_date()}</Text>

                        <Flex align="center">
                            <Icon as={FiType} mr={2} color="teal.500" />
                            <Text fontWeight="bold">Notification Type:</Text>
                        </Flex>
                        <HStack spacing={2}>
                            {notification?.notification_type.map((type, index) => (
                                <Badge key={index} colorScheme="blue" px={2} py={1} borderRadius="md">
                                    {type}
                                </Badge>
                            ))}
                        </HStack>

                        <Flex align="center">
                            <Icon as={FiUser} mr={2} color="teal.500" />
                            <Text fontWeight="bold">Destination User:</Text>
                        </Flex>
                        <Text>{notification?.notification_destination_user}</Text>
                    </Grid>

                    <Divider />

                    <Box width="100%">
                        <Text fontWeight="bold" fontSize="lg" mb={2} color="teal.500">
                            Body:
                        </Text>
                        <Box
                            p={4}
                            bg="gray.50"
                            borderRadius="md"
                            boxShadow="sm"
                            whiteSpace="pre-wrap"
                        >
                            <Text>{notification?.body}</Text>
                        </Box>
                    </Box>

                    {/* Add more fields as necessary */}
                </VStack>
            </Box>
        </Container>
    );
};