import {
    Button,
    Stack,
    useToast,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialog,
    IconButton,
    Spacer,
    ButtonGroup,
    FormLabel,
    Box,
} from '@chakra-ui/react';
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { ReportEntry } from '../../models/report_entry';
import { CommentTemplate, TemplatePlaceholders } from '../../models/comment_template';
import { CommentTemplateSchema } from '../../validations/comment_template';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommentTemplateAPI } from '../../api/CommentTemplateAPI';
import FormInput from '../forms/FormInput';
import { DeleteDialog } from '../dialog/DeleteDialog';
import { DeleteIcon } from '@chakra-ui/icons';
import Quill from 'quill';
import { formatContentWithHighlight } from '../../utils/comment_template';

interface Props {
    isOpen: boolean
    onClose: (data? : ReportEntry) => void
    comment_template?: CommentTemplate
}

export const CommentTemplateDialolg = memo(({isOpen, onClose, comment_template}: Props) => {
    const toast = useToast()
    const [loading, set_loading] = useState<boolean>(false)
    const [isOpen_delete_dialog, setIsOpen_delete_dialog] = useState(false)

	const cancelRef = useRef<HTMLButtonElement>(null)
	const quillRef = useRef<Quill>()
	const editorContainerRef = useRef<HTMLDivElement>(null)
  
	const isEdit = useMemo(() => !!comment_template, [comment_template])

	const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(CommentTemplateSchema),
    });

	register('comment'); // manually register comment 

	useEffect(() => {
		reset(comment_template)

		// define quill editor only when dialog is open
		if (!isOpen) return
			
		// timeout is a workaround to ensure that the containerRef is defined
		setTimeout(() => {
			quillRef.current = new Quill(editorContainerRef.current as HTMLDivElement, {
				placeholder: 'Enter Comment',
				modules: { toolbar: false },
			});
	
			// manually update react-hook-forms on change
			quillRef.current.on('text-change', () => {
				const editorText = quillRef.current?.getText() || ""; 
				setValue('comment', editorText);
			});

			// If comment_template.comment exists, set the initial content and highlight placeholders
			if (comment_template?.comment) {
			const formattedContent = formatContentWithHighlight(comment_template.comment);

			quillRef.current.setContents(formattedContent)
			}
		}, 100);
	}, [isOpen, setValue, comment_template]);

	const handlePlaceholderClick = (value: string) => {
		if (!quillRef.current) return

		// index is the current cursor or the end of the comment
		const index = quillRef.current.getSelection()?.index || quillRef.current.getLength();

		quillRef.current.insertText(index, `{{${value}}}`, { background: '#e2e8f0' });

		// add extra space and focus
		const newIndex = index + value.length + 4

		 // Move the cursor and add a space
		 quillRef.current.setSelection(newIndex);
		 quillRef.current.format('background', false)
		 quillRef.current.insertText(newIndex, ' ');
	};

    const onSubmit = async (data : any) => {
		set_loading(true)

		const submitFunction = isEdit ? CommentTemplateAPI.update : CommentTemplateAPI.create;

		try {
			await submitFunction(data)

			onClose()

			toast({
				title: 'Success',	
				description: "Comment Updated Successfully",
				status: 'success',
				duration: 5000,
				isClosable: true,
			})

		} catch (error) { }
		
		set_loading(false)
	}

	const deleteCommentTemplate = async () => {
		if (!comment_template?._id) return

		set_loading(true)

		try {
			await CommentTemplateAPI.remove(comment_template._id)

			onClose()

			toast({
				title: 'Success',	
				description: "Comment Deleted Successfully",
				status: 'success',
				duration: 5000,
				isClosable: true,
			})

		} catch (error) { }
		
		set_loading(false)
	}

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent as="form" onSubmit={handleSubmit(onSubmit)} minW={{lg: 600}}>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Comment Template Information
                    </AlertDialogHeader>

                    <AlertDialogBody>
						<Stack gap="4">
							<FormInput
								name="title"
								label="Title"
								register={register('title')}
								error={errors.title?.message}
								placeholder='Enter Title'
								isRequired
							/>

							<Stack gap="1">
								<FormLabel className='required' mb="0">
									Comment
								</FormLabel>

								<ButtonGroup size='sm' isAttached variant="outline">
									<Button bgColor="gray.100" onClick={() => handlePlaceholderClick(TemplatePlaceholders.Name)}>
										{TemplatePlaceholders.Name}
									</Button>

									<Button bgColor="gray.100" onClick={() => handlePlaceholderClick(TemplatePlaceholders.HeShe)}>
										{TemplatePlaceholders.HeShe}
									</Button>

									<Button bgColor="gray.100" onClick={() => handlePlaceholderClick(TemplatePlaceholders.HisHer)}>
										{TemplatePlaceholders.HisHer}
									</Button>

									<Button bgColor="gray.100" onClick={() => handlePlaceholderClick(TemplatePlaceholders.HimHers)}>
										{TemplatePlaceholders.HimHers}
									</Button>
								</ButtonGroup>

								<Box 
									ref={editorContainerRef} 
									border="1px solid" 
									borderColor="gray.300" 
									rounded="md"
									minH="200"
									p="4"
								/>
							</Stack>							
						</Stack>
                    </AlertDialogBody>

                    <AlertDialogFooter>
						{ comment_template?._id && 
							<IconButton
								icon={<DeleteIcon />}
								aria-label="Delete template"
								textColor="red.500"
								bgColor="red.50"
								_hover={{bgColor: "red.100"}}
								variant="ghost"
								onClick={() => setIsOpen_delete_dialog(true) }
							/>
						}

						<Spacer />


                        <Button ref={cancelRef} onClick={() => onClose()}>
                            Cancel
                        </Button>


                        <Button colorScheme='green' ml={3} isLoading={loading} type="submit">
                            Save Changes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>

				<DeleteDialog
					item="comment template"
					isOpen={isOpen_delete_dialog}
					action={deleteCommentTemplate}
					onClose={() => setIsOpen_delete_dialog(false)}
				/>
            </AlertDialogOverlay>
        </AlertDialog>
    );
})