import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import { Notification } from "../models/notification";
import { utils } from "../utils/date_utils";

export namespace NotificationAPI {
    export interface NotificationList {
        notifications: Array<Notification>,
        notifications_count: number
    }

    export const notification_list = async (
        per_page: number | null = null,
        page: number | null = null,
        name_search: string | undefined = undefined,
        archived: boolean | undefined = undefined
    ): Promise<NotificationList | void> => {
        return HTTPApi.post(
            "notification/notifications_list", {
                per_page: per_page,
                page: page,
                name_search: name_search,
                archived: archived,
            })
            .then((res) => {
                return {
                    notifications: res.data.notifications.map((notification: any) => {
                        return new Notification(notification);
                    }),
                    notifications_count: res.data.notifications_count
                } as NotificationList;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    export const get_notification = async (notification_id: ObjectId) => {
        return HTTPApi.get("/notification/" + notification_id.$oid)
            .then((res) => {
                return new Notification(res.data);
            });
    }
}