import { SubmissionsWithGClassWork } from "../models/gclass";
import { GClassCourseWork, GClassStudentSubmission, StateHistory } from "protobuffer-ts/dist/class_service/gclass"

export const mergeSubmissionsWithCourseWork = (
    submissions: GClassStudentSubmission[],
    courseWork: GClassCourseWork[]
): SubmissionsWithGClassWork[] => {
    return submissions.map(submission => {
        const matchingWork = courseWork.find(work => work.gclassCourseWorkId === submission.gclassCourseWorkId);
        return {
            ...submission,
            title: matchingWork?.title || 'Untitled',  // Default to 'Untitled' if no match
            due_date: matchingWork?.dueDate || undefined  // Set to null if no match
        };
    });
};

// TODO : check if we have to check StateHistory.Returned if StateHistory.TurnedIn is not defined
export const findTurnedInOrReturnedHistory = (submission: SubmissionsWithGClassWork) => {
    return submission.submissionHistory.find(
        history => history.stateHistory?.state === StateHistory.HISTORY_TURNED_IN
    ) || submission.submissionHistory.find(
        history => history.stateHistory?.state === StateHistory.HISTORY_TURNED_IN
    );
};
