import { ObjectId } from "./ObjectId"
import { ObjectId as ProtoObjectId } from "protobuffer-ts/dist/utils/object_id";

export namespace proto_utils {
    export const from_proto_object_id = (oid: ProtoObjectId): ObjectId => {
        return {
            $oid: oid.id
        }
    }

    export const from_proto_object_id_arr = (oid: ProtoObjectId[]): ObjectId[] => {
        return oid.map(e => {return from_proto_object_id(e)});
    }
}