import React from 'react'
import { FormControl, InputGroup, InputLeftElement, Input } from '@chakra-ui/react' // Assuming you're using Chakra UI
import { BsSearch } from 'react-icons/bs'

interface SearchInputProps {
	onChange: (searchTerm: string) => void
}

const SearchInput: React.FC<SearchInputProps> = ({ onChange }) => {
	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value)
	}

	return (
		<FormControl minW={{ md: '320px' }} id='search'>
			<InputGroup>
				<InputLeftElement pointerEvents='none' color='gray.400'>
					<BsSearch />
				</InputLeftElement>

				<Input rounded='base' type='search' placeholder='Search by name...' onChange={handleSearchChange} />
			</InputGroup>
		</FormControl>
	)
}

export default SearchInput
