import { Box, Center, Heading } from "@chakra-ui/react";
import React from "react";
import { FamilyAPI } from "../../api/FamilyAPI";
import { Family } from "../../models/family";
import { new_oid, to_oid } from "../../utils/ObjectId";
import { FamilyCard } from "../cards/family/FamilyCard";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { organizationId } from "../../utils/organization";

export const FamilyCreate: React.FC = () => {
    const navigate = useNavigate()
    const { user, userIsParent } = useUser()
    const default_guardians = userIsParent ? [user!._id] : []

    //! TODO: REMOVE FIXED ORG ID
    //! JIRA TICKET: https://albassem.atlassian.net/browse/BL-467

    const new_family = (): Family => {
    return {
        _id: new_oid(),
        organization: organizationId,
        name: "",
        guardians: default_guardians,
        guardians_to_not_contact: [],
        information: {
        student_living: "",
        language_spoken: "",
        preferred_contact: undefined,
        emergency_contact: {
            name: "",
            phone: "",
            email: "",
        }
        }
    }
    }

    const family = new_family();

    const create_family = (family: Family) => {
        FamilyAPI.create(family).then((res) => {
            navigate("/parent/family")
        })
    }

    return (
        <Box>
            <Center>
                <Heading size="md" mb="6">
                    Create Family
                </Heading>
            </Center>

            <FamilyCard family={family} save_family={create_family} save_on_edit={false}/>
        </Box>
    )
}