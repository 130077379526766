import React, {useEffect} from "react"
import { useState } from "react";
import {
    Grid, 
    GridItem,
    HStack,
    Text,
    VStack,
}
from '@chakra-ui/react'
import { useParams } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "../DatePicker";
import { Student } from "../../models/student";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { Violation } from "../../models/violation";
import { ViolationsAPI } from "../../api/ViolationsAPI";
import { ViolationsClass } from "./ViolationsClass";
import {BrowserView, MobileView} from 'react-device-detect';

interface TableData {
    student: Student,
    violation_entry: Violation,
    first_name: string,
    last_name: string,
    name: string
}

export const ViolationsHomeroom = React.memo((props) =>  {
    let { homeroom_id } = useParams();
    const [date, set_date] = useState<Date>(new Date());
    const [entry_data, set_entry_data] = useState<Array<TableData>>();
    const [homeroom, set_homeroom] = useState<Homeroom>();

    const on_data_change = (date: any) => {
        set_date(date)
        get_entries(date);
    }

    const get_entries = (date: Date) => {
        ViolationsAPI.get_homeroom_violation_entries(homeroom_id!, date).then((res) => {
            let entry: Array<TableData> = [];
            let data = res!;
            for(let student in data.students){
                entry.push({
                    student: data.students[student], 
                    violation_entry: data.violation_entries[student],
                    first_name: data.students[student].profile.first_name,
                    last_name: data.students[student].profile.last_name,
                    name: data.students[student].profile.first_name + " " + data.students[student].profile.last_name
                })
            }
            // Order by alpha of first_name
            entry.sort((a,b) => a.first_name.localeCompare(b.first_name));
            
            set_entry_data(undefined)
            set_entry_data(entry)
            set_homeroom(data.homeroom)
        })
    }

    useEffect(() => {
        get_entries(date);
    }, []);

    return (
        <>
            <BrowserView>
                <Grid templateColumns='repeat(4, 1fr)' gap={2}>
                    <GridItem w='100%' h='10'><Text fontSize='xl'>Homeroom: {homeroom?.name}</Text></GridItem>
                    <GridItem w='100%' h='10'></GridItem>
                    <GridItem w='100%' h='10'><Text fontSize='xl'>Date:</Text></GridItem>
                    <GridItem w='100%' h='10'><DatePicker selectedDate={date} onChange={(d) => on_data_change(d)}/></GridItem>
                </Grid>
            </BrowserView>
            <MobileView>
                <VStack>
                    <Text fontSize='xl'>Homeroom: {homeroom?.name}</Text>
                    <HStack>
                        <Text fontSize='xl'>Date:</Text>
                        <DatePicker selectedDate={date} onChange={(d) => on_data_change(d)}/>
                    </HStack>
                    
                </VStack>
            </MobileView>
            
            <ViolationsClass entry_data={entry_data}/>
        </>
    );
} );
