import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import {
    Box,
    FormLabel,
    Stack,
    useColorModeValue,
    HStack,
    Spacer,
    Heading,
    SimpleGrid,
    Text,
    Badge,
    Skeleton,
} from '@chakra-ui/react';
import { to_oid } from "../../utils/ObjectId";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { CourseAPI } from "../../api/CourseAPI";
import ExternalLinks from "../classes/ExternalLinks";
import { StudentAPI } from "../../api/StudentAPI";
import { Student } from "../../models/student";
import GClassStudentViewer from "../gclass/GClassStudentViewer";

export const StudentCourseDashboard = React.memo((props) =>  {
    let { student_id, course_id } = useParams();

    const [student, set_student] = useState<Student>()
    const [course, set_course] = useState<Course>()

    useEffect(() => {
        StudentAPI.get_student(student_id!).then((res) => {
            set_student(res!)
        })

        CourseAPI.get_course(to_oid(course_id!)).then((res) => {
            set_course(res)
        })


    }, [student_id, course_id]);


    return (
        <>
        {
            student ? (
                <Heading as="h1" size="sm" fontWeight={"bold"} mb="4">
                    {student?.profile.first_name} {student?.profile.last_name}
                </Heading>
            ) : <Skeleton height="8" maxW="300" mb="4" />
        }

        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            mb="4"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <HStack>
                    <FormLabel fontSize={"lg"} fontWeight="bold">
                        Course Information
                    </FormLabel>

                    <Spacer />

                    <ExternalLinks type="homeroom" id={course_id!} />
                </HStack>

                <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={4}>
                    <Box as={HStack} alignItems="center" mb={1}> 
                        <Text fontWeight="bold">Name</Text>

                        <Text>{course?.name}</Text>
                    </Box>

                    <Box as={HStack} alignItems="center" mb={1}> 
                        <Text fontWeight="bold">Semester</Text>

                        <Badge fontSize="xs" colorScheme="blue">
                            {course?.semester!.name}
                        </Badge>
                    </Box>

                    <Box as={HStack} alignItems="center" mb={1}> 
                        <Text fontWeight="bold">Status</Text>

                        <Badge colorScheme={course?.archived ? "red" : "green"}>
                            {course?.archived ? "Archived" : "Active"}
                        </Badge>
                    </Box>
                </SimpleGrid>
            </Stack>
        </Box>

        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            mb="4"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <FormLabel fontSize={"lg"} fontWeight="bold">
                    Google Classroom Work
                </FormLabel>

                <GClassStudentViewer studentId={to_oid(student_id!)} type={"course"} itemId={to_oid(course_id!)} />
            </Stack>
        </Box>
        </>
    );
})