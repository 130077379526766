import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import { GClassCourseWork } from "protobuffer-ts/dist/class_service/gclass";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { Course } from "protobuffer-ts/dist/class_service/course";

export namespace HomeroomAPI {
    export interface ListHomeroom {
        _id: ObjectId,
        archived: boolean,
        name: string,
        grade: string,
        teachers: Array<ObjectId>,
    }

    export interface HomeroomList {
        homerooms: Array<ListHomeroom>,
        homerooms_count: number
    }

    export const homeroom_list = async (
        per_page: number | null = null,
        page: number | null = null,
        name_search: string | undefined = undefined,
        archived: boolean | undefined = undefined,
        teacher_id: ObjectId | undefined = undefined,
        semester_id: ObjectId | undefined = undefined,

    ): Promise<HomeroomList> => {
        return HTTPApi.post(
                "homerooms/homerooms_list", {
                    per_page: per_page,
                    page: page,
                    name_search: name_search,
                    archived: archived,
                    teacher_id: teacher_id,
                    semester: semester_id,
                })
                .then((res) => {
                    return res.data as HomeroomList
                })
                .catch((e) => {
                    console.log(e);
                    throw e
                })
    }

    export const get_homeroom = async (homeroom_id: ObjectId) => {
        return HTTPApi.get("/homerooms/get/" + homeroom_id.$oid)
                .then((res) => {
                    return Homeroom.fromJSON(res.data)
                })
    }

    export const archive_homeroom = async (homeroom_id: ObjectId) => {
        return HTTPApi.post(
            "homerooms/archive", {
                _id: homeroom_id,
            }
        )
    }

    export const create = async (name: string, semester_id: ObjectId, grade: string, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "homerooms/create", {
                name: name,
                semester_id: semester_id,
                grade: grade,
                teachers: teachers
            }
        ).then((res) => {
            return res.data as Homeroom
        })
    }

    // export const clone = async (name: string, semester_id: ObjectId, grade: string, teachers: Array<ObjectId>, courses: Array<CourseClone>, google_classroom_to_be_cloned?: ObjectId) => {
    //     return HTTPApi.post(
    //         "homerooms/clone", {
    //             name: name,
    //             semester_id: semester_id,
    //             grade: grade,
    //             teachers: teachers,
    //             courses: courses,
    //             google_classroom_to_be_cloned: google_classroom_to_be_cloned
    //         }
    //     ).then((res) => {
    //         return res.data as Homeroom
    //     })
    // }

    export const update = async (homeroomId: ObjectId, semester_id: ObjectId, name: string, grade: string) => {
        return HTTPApi.post(
            "homerooms/update", 
            {
                _id: homeroomId,
                semester_id: semester_id,
                name: name,
                grade: grade,
            }
        ).then((res) => {
            return res.data as Homeroom;
        });
    };

    export const add_teachers = async (homeroom_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "homerooms/add_teachers", {
                _id: homeroom_id,
                teachers: teachers
            }
        ).then((res) => {
            return res.data as Homeroom
        })
    }

    export const remove_teachers = async (homeroom_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "homerooms/remove_teachers", {
                _id: homeroom_id,
                teachers: teachers
            }
        ).then((res) => {
            return res.data as Homeroom
        })
    }

    export const add_students = async (homeroom_id: ObjectId, students: Array<ObjectId>) => {
        return HTTPApi.post(
            "homerooms/add_students", {
                _id: homeroom_id,
                students: students
            }
        ).then((res) => {
            return res.data as Homeroom
        })
    }
    export const remove_students = async (homeroom_id: ObjectId, students: Array<ObjectId>) => {
        return HTTPApi.post(
            "homerooms/remove_students", {
                _id: homeroom_id,
                students: students
            }
        ).then((res) => {
            return res.data as Homeroom
        })
    }

    export const get_gclass_url = async (homeroom_id: ObjectId) => {
        return HTTPApi.get("/homerooms/get_gclass_url/" + homeroom_id.$oid)
                .then((res) => {
                    return res.data as string
                })
    }

    export const get_gclass_work = async (homeroom_id: ObjectId) => {
        return HTTPApi.get("/homerooms/get_gclass_work/" + homeroom_id.$oid)
                .then((res) => {
                    return res.data.map((e: any) => GClassCourseWork.fromJSON(e));
                })
    }

    export const add_attendance_teacher = async (course_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "homerooms/add_attendance_teacher", {
                _id: course_id,
                teachers: teachers
            }
        ).then((res) => {
            return res.data as Homeroom
        })
    }


    export const get_courses = async (homeroom_id: ObjectId) => {
        return HTTPApi.post(
            "homerooms/get_courses", {
                _id: homeroom_id,
            }
        ).then((res) => {
            return res.data as Course[]
        })
    }
}
