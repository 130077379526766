import { ObjectID } from "bson";
import { ObjectId as ProtoObjectId } from "protobuffer-ts/dist/utils/object_id";

export interface ObjectId {
    $oid: string;
}

export const to_oid = (id: string): ObjectId => {
    return {
        $oid: id
    }
}

export const new_oid = (): ObjectId => {
    let id  = new ObjectID();
    return {
        $oid: id.toString()
    }
}

export const new_oid_proto = (): ProtoObjectId => {
    let id  = new ObjectID();
    return {
        id: id.toString()
    }
}

