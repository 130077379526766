import React from 'react';
import { Switch } from '@chakra-ui/react';
import { Control, Controller } from 'react-hook-form';

interface FormSwitch {
    name: string;
    control: Control<any>;
}

const FormSwitch: React.FC<FormSwitch> = ({ name, control }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <Switch
                    size="lg"
                    id={name}
                    isChecked={value}
                    onChange={(e) => onChange(e.target.checked)}
                />
            )}
        />
    );
};

export default FormSwitch;